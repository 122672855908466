.container {
  .itemDraggingOver {
    position: relative;
    margin: 0;
    padding: 0;

    li {
      box-shadow: 0 4px 7px -2px rgba(0, 0, 0, 0.11) !important;
      color: var(--violet) !important;
      svg path {
        fill: var(--violet) !important;
      }
    }

    &:nth-child(1) {
      height: 60px !important;
      transform: none !important;
      transition: none !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

}
