@import "../../../styled/breakpoints";

.tableBriefcaseCustom {
  width: 100%;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  thead {
    background-color: var(--easy);
    border: 1px solid transparent;
    height: 64px;
    color: var(--white);

    @include breakpoint(sm) {
      height: 41px;
    }
  }

  th {
    padding: 15px 0;
  }

  td {
    border-bottom: 1px solid var(--light-blue-grey);
    padding: 1.5rem 0;
    font-size: 14px;
  }

  thead > tr > th:first-child,
  tbody > tr > td:first-child {
    padding-left: 27px;

    @include breakpoint(sm) {
      padding-left: 10px !important;
    }
  }

  thead > tr > th:last-child,
  tbody > tr > td:last-child {
    padding-right: 70px;

    @include breakpoint(sm) {
      padding-right: unset;
    }
  }

  thead > tr > th:first-child {
    border-top-left-radius: 2rem;

    @include breakpoint(sm) {
      border-top-left-radius: 0 !important;
    }
  }

  thead > tr > th:last-child {
    border-top-right-radius: 2rem;

    @include breakpoint(sm) {
      border-top-right-radius: 0 !important;
    }
  }
}
