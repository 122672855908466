.briefcaseModal {


  :global {
    .ant-modal-header {
      background-color: var(--purple);
    }

    .ant-modal-content .ant-modal-title {
      color: var(--white);
    }

    .ant-modal-content {
      border: none;
      width: 476px;
      height: 195px;
      border-radius: 8px;
      margin: 0px;
    }

    .ant-modal-content .ant-modal-close {
      display: flex;
      color: var(--white);
    }

    .ant-modal-body p {
      font-family: Inter;
      font-size: 20px !important;
      font-weight: 600 !important;
      line-height: 23px !important;
      letter-spacing: 0em !important;
      text-align: center;
      padding-top: 55px !important;
      padding-inline: 30px !important;
      width: 416px !important;
    }

    .ant-modal-content .ant-modal-body {
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .ant-modal-footer {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      padding-top: 21px;

      .ant-btn {
        background-color: #3331B1;
        color: var(--white);
        border-radius: 4px;
        width: 156px;
        height: 39px;
      }
    }


    .ant-btn {
      color: var(--white);
      border: none;
      justify-content: center;

      background-color: #3331B1;
      color: #FFFFFF;
      border-radius: 4px;
      width: 156px;
      height: 39px;
    }

    .ant-btn:not([disabled]):hover {
      background-color: var(--white);
      color: #3331B1;
      border: 1px solid #3331B1;
    }

    .ant-btn>span {
      padding: 4rem;
    }
  }

  p:first-child {
    font-weight: 700;
  }

  p:nth-child(3) {
    padding-bottom: 1rem;
  }

  p:nth-child(4) {
    padding-bottom: 0rem;
  }

  .textModal {}

  .showButton {
    color: rgba(0, 0, 0, 0.4);
    text-decoration-line: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.mobileContainer {
  min-width: 40rem;
}
