.itemContainer {
  z-index: 99;

  .buttonItem {
    width: 100%;
    height: 80px;
    border-radius: 0;
    background: var(--violet) !important;
    color: var(--white) !important;
    border: none;

    &:focus,
    &:hover {
      background: var(--violet);
      color: var(--white);
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 17px;
      font-weight: 700;
      gap: 8px;

      .hideSwipeBar,
      .swipeBar {
        height: 4px;
        width: 70px;
        border-radius: 3px;
        background: var(--white);
        transition: all .2s;
        visibility: visible;
        opacity: 1;
      }

      .hideSwipeBar {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .listContainer {
    max-height: 200px;
    overflow-y: auto;

    .hideItems,
    .listItems {
      display: block;
      transition: all .3s;

      .listItem {
        font-weight: 500;
        text-align: center;
        margin: 0;
        padding: 17px 0;
        text-transform: capitalize;

        .listItemWithIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
      }

      .listItemMain {
        background: var(--violet) !important;
        font-size: 17px;
        font-weight: 700;
        color: var(--white);
      }
    }

    .hideItems {
      display: none;
      opacity: 0;
      transition: all .3s;
    }
  }
}
