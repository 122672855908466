@import "../../../styled/breakpoints";

.table {
  background-color: var(--white);
  max-width: 100%;
  margin: 0 6% 100px 6% !important;
  border-radius: 28px 28px 0 0;
  border-collapse: collapse !important;

  thead {
    text-align: left !important;

    tr {
      height: 60px !important;

      th {
        height: 60px !important;

      }

      @include breakpoint(sm) {
        height: unset !important;

        th {
          height: unset !important;
        }
      }

      th:nth-child(1) {
        width: 86px;
        min-width: 86px;
        max-width: 86px;
        padding-left: 27px;
      }

      th:nth-child(2) {
        border: none;
        margin: 0;
        max-width: 400px !important;
        min-width: 400px !important;
        width: 400px !important;
      }

      th:nth-child(3) {
        padding-left: 3px;
        width: 170px !important;
      }

      th:nth-child(4) {
        width: 302px;
      }

      th:nth-child(5) {
        width: 101px;
      }

      th:nth-child(6) {
        width: 68px;
      }

      @include breakpoint(sm) {
        height: unset !important;

        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(5),
        th:nth-child(6) {
          max-width: unset !important;
          min-width: unset !important;
          width: unset !important;
        }
      }
    }

    @include breakpoint(sm) {
      tr {
        background: var(--easy) !important;
        border: 1px solid var(--easy);

        th:nth-child(2) {
          padding-left: 10px;
        }
      }
    }
  }

  .headerIcon {
    display: flex;
    align-items: center;
    gap: 9px;

    svg {
      transform: translateY(1px);
      width: 8px;
      height: 8px;
    }
  }

  tbody {
    tr {
      height: unset !important;
      position: relative !important;
      top: auto !important;
      left: auto !important;
    }
  }

  .tableFind {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    @include breakpoint(sm) {
      display: block;
      line-height: unset;
      font-size: unset;
      gap: 0;
    }
  }

  .headerMobile {
    font-size: 13px !important;
  }

  .tablePosition {
    position: relative;

    .cellInput {
      .inputContract {
        border-top: 0;
        border-bottom: 0;
        border-radius: 0;
        border-left: 3px solid var(--input-selector);
        border-right: 3px solid var(--input-selector);
        width: 150px;

        &:focus {
          outline: none;
          box-shadow: none;
          border-left: 3px solid var(--input-selector);
          border-right: 3px solid var(--input-selector);
          background: rgba(38, 86, 208, 0.1);
        }
      }

      .inputDotUp,
      .inputDotDown {
        background-color: var(--input-selector);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .inputDotUp {
        margin-left: -3px;
      }

      .inputDotDownContainer {
        display: flex;
        width: 150px;
        height: 20px;
        justify-content: end;

        .inputDotDown {
          transform: translateX(2.5px);
        }
      }
    }

    .statusViewCtn {
      padding-inline-start: 100px;
      background-color: var(--status-view);

      .dateText {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: var(--black-secondary);
      }

      .subText {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.46);
      }

      @include breakpoint(sm) {
        padding-inline-start: 25px;
      }
    }

    .tableStatus {
      padding: 7px 15px;
      margin-right: 2rem;
      display: flex;
      align-items: center;
      border-radius: 14px;
      width: 132px;
      height: 31px;

      p {
        padding-left: 0.4rem;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }

      .grow {
        flex-grow: 1;
      }

      @include breakpoint(sm) {
        width: 90px;
        height: 22px;
        padding: 7px 10px;

        p {
          padding-left: 3px !important;
          font-size: 9px;
        }

        svg {
          transform: translateY(-1px);
          font-size: 10px;
        }
      }
    }

    .tableCursor {
      cursor: pointer;
    }

    .tableRow {
      height: 60px !important;

      &:hover {
        .containerIcons {
          visibility: visible;
        }
      }

      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }

      td:nth-child(2) {
        max-width: 400px !important;
        min-width: 400px !important;
        width: 400px !important;
      }

      @include breakpoint(sm) {
        td:nth-child(2) {
          max-width: unset !important;
          min-width: unset !important;
          width: unset !important;
        }
      }

      td:nth-child(5) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .link {
        text-decoration: none;
        color: black;

        &:hover {
          color: black;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 360px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;

        @include breakpoint(sm) {
          font-size: 9px;
          width: 170px !important;
        }
      }

      .mobileCell {
        text-align: center;
      }
    }

    .containerIconsCell {
      max-width: 86px !important;
      width: 86px !important;
      min-width: 86px !important;
      padding-left: 27px !important;
    }

    .containerIcons {
      display: inline-flex;
      align-items: center;
      margin-right: 7px;
      gap: 15px;
      visibility: hidden;

      .renameIcon {
        cursor: pointer;
      }

      .dndIcon {
        transform: translateY(3px);
      }
    }
  }

  .tableDivPosition {
    display: flex;
    flex-direction: column;

    .tableDropdown {
      display: flex;
      margin: 0.5rem 0;

      .iconMargin {
        margin-left: 30px;
        margin-right: 8px;
        color: var(--black-secondary);

        @include breakpoint(sm) {
          margin-left: unset;
        }
      }

      .subText {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.46);
      }

      .iconDisplay {
        display: none;
      }

      .iconSigned {
        color: #56bb6a;
      }

      .dots {
        margin-right: 7px;
        margin-left: 12px;
        position: relative;
        position: relative;
        top: -3px;
        height: 20px;
      }

      @include breakpoint(sm) {
        flex-direction: column;
      }
    }
  }

  .search input {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    width: 315px;
  }

  .actionButtom {
    max-width: 100px;
    height: 40px;
    font-weight: 400;
    border-color: var(--light-grey);
    color: var(--new-dark-grey);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-top: 3px;
      fill: var(--new-dark-grey);
    }
  }

  @include breakpoint(sm) {
    margin: unset !important;
  }
}

.emptyProjectBody,
.emptyTableBody {
  height: 385px;
  text-align: center;
  padding-top: 39px;
  width: 50% !important;
  margin: auto;

  span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    text-align: center;
    color: #aba7a7;

    @include breakpoint(sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}


.emptyProjectBody {
  @include breakpoint(sm) {
    width: 70% !important;
  }
}

.paginationContainer {
  text-align: right;
  max-width: 100%;
  margin: 44px 6% 0 6%;
  position: relative;

  .pagination {
    display: inline-block;
    border: 1px solid var(--new-line-gray);
    border-radius: 14px;
    height: 51px;
    margin-right: 61px;

    :global {

      .ant-pagination-next,
      .ant-pagination-item {
        align-items: center;
        background-color: transparent;
        border-radius: 14px;
        border: none;
        color: var(--new-black);
        display: inline-flex;
        height: 49px;
        justify-content: center;
        margin-right: 0;
        width: 51px;

        &:hover a {
          color: var(--new-black);
        }
      }

      .ant-pagination-item-link-icon {
        color: var(--purple) !important;
      }

      .ant-pagination-prev {
        display: none;
      }

      .ant-pagination-item-active {
        background-color: var(--easy);
        border-color: transparent;

        &:hover a,
        a {
          color: var(--white);
        }
      }

      .ant-pagination-next {
        border: 1px solid var(--new-line-gray);
        position: absolute;
        right: 0;

        svg {
          fill: var(--purple);
        }
      }
    }
  }

  @include breakpoint(sm) {
    text-align: center;

    .pagination {
      height: 31px;

      :global {

        .ant-pagination-next,
        .ant-pagination-item {
          font-weight: 700;
          width: 31px;
          height: 31px;
        }

        .ant-pagination-next {
          right: 70px;
        }
      }
    }
  }
}

.emptyMessage {
  padding: 50px !important;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--new-text-gray);
  font-weight: 500;
  border: none;
}

.archiveIcon {
  width: 20px !important;
  height: 18px !important;
}
