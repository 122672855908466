@import '../../styled/breakpoints';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  background: var(--common-bg);

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
    width: 100%;
    @include breakpoint(sm) {
      margin-top: 10px;
      padding: 0 30px;
    }
  }

  .header {
    align-items: center;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    min-height: 50px;
    width: 100vw;

    .headerTitle {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      color: var(--blue-headline);
      margin: 0 auto;
      max-width: 90%;
      @include breakpoint(sm) {
        max-width: calc(100% - 60px);
        font-size: 2.1rem;
        line-height: 1;
      }
    }
  }
}
