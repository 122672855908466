@import "../../../styled/breakpoints";

.globalSearchContainer {
  width: 80%;
  min-width: 80%;
  max-width: 80%;

  @include breakpoint(sm) {
    width: 100%;
    min-width: auto;
    max-width: 100%;
  }

  .globalSearch {
    border-radius: 2rem;
    box-shadow: var(--shadow);

    &:hover,
    &:focus {
      border-color: var(--purple);
      box-shadow: none;
    }

    .projectSearch {
      border-radius: 2rem;
      box-shadow: none;
    }

    :global {

      .ant-input {
        width: 100% !important;
      }

      .ant-input-prefix {
        color: var(--purple);
      }

      .ant-input-prefix {
        svg {
          width: 1.7rem;
          height: auto;
        }
      }

      .ant-input-suffix {
        svg {
          width: 1.3rem;
          height: auto;
        }
      }
    }
  }

  :global {
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: var(--purple);
    }

    .ant-input-affix-wrapper-focused {
      border-color: var(--purple);
    }
  }
}
