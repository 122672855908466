@import '../../../styled/breakpoints';

.notificationsContainer {
  position: relative;
  padding: 27px 44px 27px 33px;

  .closeIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    color: var(--close-icon);
    font-weight: 700;
  }

  .title {
    color: var(--blue-main);
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
  }

  .subTitle {
    font-weight: bold;
    font-size: 14px;
  }

  .subTitleBody {
    font-size: 13px;
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.46);
  }

  .subTitleBodyContainer {
    display: flex;
    gap: 7px;
    align-items: start;

    span {
      transform: translateY(1px);
    }
  }

  .notificationContainer {
    margin-top: 1rem;
    width: fit-content;
    max-width: 27rem;
  }

  :global {
    .ant-divider {
      border-top-color: transparent;
    }
    .ant-divider-horizontal {
      margin: 1rem 0;
    }
  }
}
