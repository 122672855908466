@import "./breakpoints";
@import "./variables";

.briefcase {
  main > h1 {
    margin-top: 35px;
    margin-bottom: 35px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
    color: var(--blue-headline);
  }

  .ant-tabs {
    margin-bottom: 40px;
    overflow: visible;
  }

  .ant-btn {
    font-weight: 600;
  }
}

.contractLoading {
  height: 40rem;
}

.flow-contract {
  background-color: var(--common-contract-bg);
}

.logout {
  main {
    text-align: center;

    .ant-spin {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.thankYouPage {
  .wrapper {
    .wrapperContent {
      .thankYouTitle {
        font-weight: 700;
        font-size: 5.6rem;
        color: var(--blue-headline);
      }

      .thankYouDescription {
        max-width: 921px;
        margin: 0 auto 35px;
        color: var(--blue-headline);
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 32px;
      }

      @include breakpoint(md) {
        padding: 0 40px;
        width: unset !important;
      }

      @include breakpoint(sm) {
        padding: 0 40px;
        width: unset !important;

        .thankYouTitle {
          font-size: 3.9rem !important;
          margin: 10px 0;
        }

        .thankYouDescription {
          font-size: 1.5rem;
          line-height: 18px;
          margin-bottom: unset;
        }
      }
    }
  }
}

.thankYouPage,
.error-page {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;

    .wrapperContent {
      width: 940px
    }
  }

  .error-cnt {
    padding-inline: 2.5em;
    padding-bottom: 3em;

    h1 {
      margin-bottom: 1em;
    }

    h3 {
      margin-bottom: 1.5em;
    }

    .ant-btn {
      margin-right: 1em;
    }
  }

  > .container {
    h1 {
      margin: 70px 0 23px;
      color: var(--blue-headline);
      text-align: center;
      font-size: 5.6rem;
      line-height: 1.2;
      font-weight: 700;

      @include breakpoint(sm) {
        font-size: 3.9rem;
        margin: 26px 0 16px 0;
      }
    }

    h3 {
      max-width: 921px;
      margin: 0 auto 35px;
      color: var(--blue-headline);
      text-align: center;
      font-size: 2.2rem;
      font-weight: 400;
      line-height: 32px;

      @include breakpoint(sm) {
        font-size: 1.5rem;
        line-height: 18px;
        margin-bottom: 17px;
      }
    }

    nav {
      margin: 35px auto;
      text-align: center;

      > a,
      > button {
        height: 50px;
        padding: 16px 40px !important;
        line-height: 1;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    @media screen and (max-width: $tablet) {

      h1,
      h3,
      nav {
        max-width: 100%;
      }

      nav {
        display: flex;
        flex-direction: column;

        > a,
        > button {
          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.product {
  .ant-tooltip {

    .ant-tooltip-arrow-content,
    .ant-tooltip-inner {
      background-color: #a087f1;
    }
  }

  .ant-dropdown-menu-submenu {
    width: 100%;
  }
}
