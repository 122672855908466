@import "../../../../styled/breakpoints";

.logoPreview {
    margin-top: 17px;
    @include breakpoint(sm) {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }

    .logo {
        height: 83px;
    }

    .placement {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .text {
        color: rgba(14, 13, 40, 0.7);
        display: flex;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .placementContainer {
        padding-left: 10px;
        @include breakpoint(sm) {
            margin-top: 18px;
            padding-left: 0px !important;
            max-width: 100%;
        }
    }
}

.mainContainer {
    margin-bottom: 39px;
    min-height: 161px;
    @include breakpoint(sm) {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #D4D3EF;
        border-radius: 0;
        margin-bottom: 21px;
        min-height: 151px;
        padding-bottom: 0;
        padding-top: 8px;
    }
}
