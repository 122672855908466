@import "../../../../styled/breakpoints";
.paginationContainer {
  display: flex;
  list-style-type: none;
  text-align: right;
  max-width: 100%;
  padding-top: 44px;
  justify-content: flex-end;
  gap: 10px;
  background-color: var(--bg-mainContainer);
  align-items: center;
  @include breakpoint(sm) {
    justify-content: center;
    gap: 6px;
  }

  .paginationItemContainer {
    border: 1px solid var(--new-line-gray);
    border-radius: 14px;

    box-sizing: border-box;
    display: flex;
  }
  .paginationItem {
    display: flex;
    align-items: center;
    padding: 14px 22px;
    height: 51px;
    width: 51px;
    text-align: center;
    color: var(--new-black);
    letter-spacing: 0.01071em;
    border-radius: 14px;
    line-height: 1.43;
    font-size: 18px;
    min-width: 32px;
    justify-content: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
    @include breakpoint(sm) {
      text-align: center;
      height: 31px;
      width: 31px;
      font-weight: 600;
      font-size: 14px;
      padding: 11px 8px;
      justify-content: center;
      min-width: 30px;
    }
  }

  .paginationItem.iconColor {
    color: var(--easy);
    height: 50px;
    width: 50px;
    &:hover {
      color: var(--new-black);
      cursor: pointer;
    }
    @include breakpoint(sm) {
      height: 30px;
      width: 30px;
    }
  }
}

.selected {
  background-color: var(--header-table);
}
.paginationItem.selected {
  color: var(--new-black);
  &:hover {
    cursor: pointer;
  }
  @include breakpoint(sm) {
    font-weight: 800;
  }
}
.disabled {
  pointer-events: none;
}
