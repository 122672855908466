@import '../../styled/variables';

.header {
    position: fixed;
    top: 0;
    z-index: 210;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    min-height: var(--header-height);
    background: var(--head-bg);
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px;

    > div {
        display: flex;
        justify-content: space-between;
    }

    nav {
        a {
            position: relative;
            padding: 22px 0;
            font-size: 1.6rem;
            line-height: 1.9rem;
            font-family: 'Inter', Arial, sans-serif;
            font-weight: 500;
            color: var(--base-black);

            &:not(:last-of-type),
            &:not(:last-child) {
                margin-right: 4rem;
            }

            &.active {
                font-weight: bold;
            }

            &:hover {
                color: rgb(96, 43, 245);

                &:before {
                    background-color: rgb(96, 43, 245);
                }
            }
        }
    }

    @media screen and (max-width: $desktop) {
        min-height: var(--header-mobile-height);
    }

    :global {
        .ant-btn.ant-btn-icon-only {
            background-color: transparent;
            border-color: transparent;

            svg {
                font-size: 19px;
                fill: var(--base-black);
            }
        }
    }
}

.mobileDropDown {
    width: 100%;

    ul {
        width: 100%;
        margin: 0 !important;
        border-top: none !important;
        border-radius: 0 !important;

        li {
            > div, a {
                min-height: 50px;
                padding: 0 30px;
                font-size: 14px;
                line-height: 50px;
                font-weight: 500;
                color: #2b2e32;
                background-color: #ffffff;
                border-bottom: 1px solid #eaeaea;
            }
        }
    }
}
