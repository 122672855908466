@import "../../../../styled/breakpoints";

.container {
  .borderTop {
    background-color: var(--common-intro-bg);
    border: #d4d3ef 1px solid;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    height: 100px;
    margin-bottom: -90px;
    width: 100%;
    z-index: 1;
    @include breakpoint(sm) {
      height: 130px !important;
    }
  }

  margin: 0 auto;
  max-width: 810px;
  background-color: var(--common-intro-bg);

  .titleContainer {
    background-color: var(--common-intro-bg);
    padding: 18px 0;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--blue-hover);
      text-align: center;
    }
  }

  .quillContainer {
    background-color: var(--white);
    margin-bottom: 31px;
    @include breakpoint(sm) {
      margin-top: 25px;
    }
    &.quillTop {
      :global {
        .quill {
          padding-top: 0px;
        }
      }
    }
    :global {
      .quill-field.quill-field-disabled {
        //display: none;
      }
    }
  }
}

%menuWrapper {
  width: 180px;
  background-color: #fff;
  border: 1px solid var(--light-blue-grey);
  border-bottom: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

%menuItem {
  margin: 0;
  font-family: var(--font_inter);
  font-weight: 600;
  font-size: 14px;
  color: var(--dark-grey);
  border-bottom: 1px solid var(--light-blue-grey);

  &:hover {
    color: var(--blue-headline);
  }

  svg {
    max-width: 16px;
    height: auto;
    margin-left: 10px;
    vertical-align: middle;
  }
}

:global {
  .quill {
    background-color: var(--common-intro-bg);
    height: inherit;
    position: relative;
    z-index: 8;
    margin-top: 20px;
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: none;
  }

  .ql-toolbar {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 679px;
    padding: 9px 30px !important;
    position: relative;
    width: 90%;
    z-index: 10;
    margin-top: 23px;
    @include breakpoint(sm) {
      transform: translateY(-45px);
      width: 78%;
    }
    .ql-formats {
      @include breakpoint(sm) {
        margin: 0 auto !important;
        &:nth-child(1) {
          margin: 0px auto 10px auto !important;
          background: #ffffff;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          padding: 5px;
          width: 90%;
          span {
            width: 100%;
            max-height: 75px;
          }
        }
      }
    }
    @include breakpoint(sm) {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      flex-flow: wrap;
      padding: 0 !important;
      span {
        width: fit-content;
      }
    }

    &--fixed {
      position: fixed;
      top: 105px;
      background-color: var(--common-bg);
      transform: translate3d(0, 0, 0);
      box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 5px -1px;
    }
  }

  .ql-editor {
    position: relative;
    min-height: 1056px;
    padding: 35pt 35pt;
    cursor: text;
    overflow: visible;
    background-color: var(--white);
    -webkit-tap-highlight-color: initial;
    border: 0px;
    box-shadow: rgba(60, 64, 67, 0.15) 0px 4px 3px 1px;
    outline: transparent solid 1px;
    color: black;
    white-space: normal;
    font-family: var(--font_verdana);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    @include breakpoint(sm) {
      padding: 17pt 17pt;
      margin-top: -20px;
    }

    &:after {
      background-color: var(--white);
      height: 9px;
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
    }

    br {
      display: inline-block;
      width: 100%;
      height: 24px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }

    .quill-field {
      display: inline-block;
      cursor: pointer;

      &.quill-field-disabled {
        cursor: default;
        user-select: none;
      }
    }

    .quill-divider {
      position: relative;
      bottom: 0;
      left: 50%;
      margin: 28px auto;
      width: calc(100% + 94px);
      height: 24px;
      transform: translateX(-50%);
      background-color: var(--white);
      border: none;
    }

    .quill-table {
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100% !important;
      max-width: 100% !important;
      border: 1px dashed var(--white);

      tr {
        height: 33px;
      }

      th,
      td {
        text-align: left;
        padding: 4px;
        border: 1px dashed var(--white);

        img {
          vertical-align: sub;
        }
      }
    }
  }

  .ql-toolbar button {
    &.ql-divider {
      background: center center no-repeat url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='113.28px' height='113.281px' viewBox='0 0 113.28 113.281' style='enable-background:new 0 0 113.28 113.281;'%3E%3Cg%3E%3Cg%3E%3Cpath d='M80.872,3.471l-60.903,98.662c-2.122,3.436-1.055,7.938,2.38,10.057c1.196,0.738,2.521,1.092,3.832,1.092 c2.451,0,4.846-1.231,6.227-3.472l60.903-98.663c2.121-3.435,1.055-7.937-2.381-10.056C87.496-1.029,82.99,0.036,80.872,3.471z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: 16px;

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='113.28px' height='113.281px' viewBox='0 0 113.28 113.281' style='enable-background:new 0 0 113.28 113.281;'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%2306c' d='M80.872,3.471l-60.903,98.662c-2.122,3.436-1.055,7.938,2.38,10.057c1.196,0.738,2.521,1.092,3.832,1.092 c2.451,0,4.846-1.231,6.227-3.472l60.903-98.663c2.121-3.435,1.055-7.937-2.381-10.056C87.496-1.029,82.99,0.036,80.872,3.471z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  .ant-menu {
    z-index: 11;
    position: relative;
    @extend %menuWrapper;
  }

  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical-left > .ant-menu-item,
  .ant-menu-vertical-right > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    @extend %menuItem;
  }
}
