.contractFooter {
    background-color: var(--white);
    box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    justify-content: space-between;
    padding: 8px 35pt 35pt 35pt;
    position: relative;
    flex-direction: column;

    .previewImg {
        width: 100px;
    }
    &.logoTop {
        position: relative;
        bottom: -25px;
        z-index: 9;
        text-align: center;
        padding: 0;
        min-height: 90px;
        width: calc(100% - 2px);
        margin-left: 1px;
        &::after {
            background-color: var(--white);
            bottom: -4px;
            content: "";
            height: 4px;
            position: absolute;
            width: 100%;
        }
        .footerContainer {
            justify-content: center;
        }
    }
    &.noLogoBottom {
        justify-content: flex-end;
    }
    .footerContainer {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
    &.noLogoBottom,
    &.skuLonly {
        .footerContainer {
            justify-content: flex-end;
        }
    }
}

:global {
    .ant-upload-list-item-info {
        display: none;
    }
}
