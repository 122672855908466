@import "../../styled/breakpoints";

.group {
    display: flex !important;
    justify-content: center;
    margin-bottom: 37px;
    @include breakpoint(md) {
        flex-direction: column;
        margin-bottom: 35px;
        align-items: center;
    }

    .button {
        align-items: center;
        background-color: #F7F7F7 !important;
        border: 1px solid #D4D4D4 !important;
        color: #5F5F5F !important;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        height: 100%;
        justify-content: center;
        line-height: 22px;
        min-width: 235px;
        padding: 13px 25px;
        @include breakpoint(md) {
            margin-bottom: 6px;
            border-radius: 8px !important;
            font-size: 2.4rem;
            justify-content: flex-start;
            width: 270px;
        }
        @include breakpoint(sm) {
            width: 260px;
        }

        &:first-child {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
        }

        &:last-child {
            border-bottom-right-radius: 8px !important;
            border-top-right-radius: 8px !important;
        }

        &.edit {
            &:hover {
                background-color: var(--light-grey) !important;
            }
        }

        &.download {
            &:hover {
                background-color: var(--easy) !important;
                color: var(--white) !important;
            }
        }

        &.review {
            &:hover {
                background-color: var(--blue-main) !important;
                color: var(--white) !important;
            }
        }

        &.signature {
            &:hover {
                background-color: var(--orange) !important;
                color: var(--white) !important;
            }
        }
    }

    :global {
        .ant-dropdown-open {
            background-color: var(--light-grey) !important;
        }
    }
}

.iconButton {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    vertical-align: sub;
    @include breakpoint(sm) {
        margin-right: 10px;
        align-self: flex-end;
    }
}

.dropdownIcon {
    color: var(--blue-arrow);
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 5px;
    transform: rotate(-180deg);
    transition: all 0.5s ease;
    @include breakpoint(md) {
        margin: 0;
        margin-top: 3px;
        position: absolute;
        right: 15px;
    }
}

.link {
    text-align: center;
    display: block;
    border: none;
    background: none;
    text-decoration-line: underline;
    color: var(--blue-main) !important;
    margin: 0 auto 48px auto;
    cursor: pointer;
    @include breakpoint(sm) {
        font-size: 18px;
    }

    &:hover {
        color: var(--blue-main) !important;
    }
}

:global {
    .ant-dropdown-menu.CongratulationButtons-Dropdown-options {
        padding: 10px !important;
        @include breakpoint(sm) {
            display: none;
        }

        li span {
            font-size: 1.6rem;
            font-weight: 500;
        }
        .ant-dropdown-menu-item-icon {
            margin-right: 5px;
        }
    }
    .ant-dropdown-open svg {
        transform: none;
    }
    .thank-you-page {
        background-color: var(--white);
    }
    .CongratulationButtons-Dropdown-options:not(.ant-dropdown-menu) {
        border-radius: 0;
        bottom: 0;
        left: 0;
        padding: 60px 29px 27px 60px !important;
        position: fixed;
        width: 100vw;
        z-index: 99;
        background-color: #F7F7F7;
        &::before {
            background-color: #dcdcdc;
            border-radius: 3px;
            content: "";
            display: inline-block;
            height: 4px;
            left: calc(50% - 35px);
            position: absolute;
            top: 19px;
            width: 70px;
        }

        li span {
            font-size: 2.4rem;
        }
        .ant-menu-item-icon {
            margin-right: 7px;
            margin-left: 0 !important;
        }
        .ant-menu-item {
            padding: 0 !important;
            margin: 0 !important;
            border: none;
            line-height: 1 !important;
        }
    }
}
