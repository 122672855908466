@import '../../styled/variables';
@import '../../styled/breakpoints';

.wrapper {
  position: relative;

  :global {
    .react-pdf__Document {
      max-width: 100%;
    }

    .react-pdf__Page canvas {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    .react-pdf__message {
      font-size: 13px;
      line-height: 185%;
      text-align: center;
      color: var(--dark-grey);
    }

    .ant-steps-item-wait {
      .ant-steps-item-container {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}

.pdfWrapperFull {
  composes: wrapper;
  margin-top: 50px;

  .pdfNavigation {
    > button {
      position: absolute;
      top: calc(50% - 50px);
      left: -40px;
      width: 50px;
      height: 50px;
      transform: translateY(-50%);

      &:last-child {
        left: auto;
        right: -40px;
      }
    }
  }
}

.header {
  position: relative;
  width: 100vw;
  max-width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 2rem 1rem;
  background-color: #fff;
  text-align: center;

  h1 {
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 133%;
    text-align: center;
    color: var(--blue-headline);
  }

  :global {
    .ant-steps {
      display: inline-grid;
      grid-auto-columns: minmax(100px, 100px);
      grid-auto-flow: column;
      //grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      width: auto;
      margin: 0 auto;
      padding: 0;
      justify-content: center;
      @include breakpoint(sm) {
        padding-bottom: 10px !important;
      }
    }

    .ant-steps-item-container {
      display: flex;
      flex-direction: column;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      position: relative;
      padding-left: 0; //24px;
      white-space: normal;

      &:first-child {
        padding-left: 0;

        &:after {
          left: 50%;
        }
      }

      &:last-child {
        &:after {
          right: 50%;
          left: auto;
        }
      }

      > div {
        z-index: 3;
        position: relative;
      }

      &:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 20px;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        margin: auto;
        background-color: #E2EAF5;
      }

      &:before {
        z-index: 2;
        position: absolute;
        top: 20px;
        left: 0;
        display: block;
        width: 50%;
        height: 2px;
        margin: auto;
        background-color: var(--blue-main);
      }
    }

    .ant-steps-item-content {
      text-align: center;
    }

    .ant-steps-item-title {
      padding: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #B1C7E2;

      &:after {
        content: none;
      }
    }

    .ant-steps-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: auto;
      margin-bottom: 12px;
      border: 2px solid #DFE8F3;

      > .ant-steps-icon {
        top: initial;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        border-color: #DFE8F3;

        > .ant-steps-icon {
          color: #B1C7E2;
        }
      }
    }

    .ant-steps-item-process {
      &.ant-steps-item {
        &:not(:first-child):before {
          content: '';
        }
      }

      > .ant-steps-item-container > .ant-steps-item-icon {
        background-color: var(--blue-main);
        border-color: var(--blue-main);
      }
    }

    .ant-steps-item-finish {
      &.ant-steps-item {
        &:after {
          background-color: var(--blue-main) !important;
        }
      }

      .ant-steps-item-container:hover {
        .ant-steps-item-icon {
          border-color: var(--blue-main) !important;

          .ant-steps-icon {
            color: var(--light-border-blue) !important;
          }
        }

        .ant-steps-item-title {
          color: var(--light-border-blue) !important;
        }
      }

      .ant-steps-item-icon {
        background-color: var(--blue-main);
        border-color: var(--blue-main);

        .ant-steps-icon {
          color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    h1 {
      margin-bottom: 10px;
      font-weight: 600;
    }

    :global {
      .ant-steps {
        max-width: 100%;
        justify-content: initial;
        grid-auto-columns: minmax(60px, 1fr);
        overflow-x: auto;
      }

      .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        &:before,
        &:after {
          top: 16px;
        }
      }

      .ant-steps-item-icon {
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 34px;

        > .ant-steps-icon {
          font-weight: 500;
          font-size: 16px;
        }
      }

      .ant-steps-item-content {
        display: none;
      }
    }
  }
}

.messageBox {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  span, div {
    width: 100%;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    color: #A087F1;
  }
}

.intro {
  position: relative;
  margin: 12rem auto;
  padding: 4rem;
  max-width: 580px;
  background-color: var(--common-intro-bg);
  border: 1px solid var(--common-intro-border);
  border-radius: 10px;
  @include breakpoint(sm) {
    margin: 12rem 20px;
  }

  h1 {
    margin-bottom: 22px;
    text-align: center;
    font-weight: bold;
    font-size: 4.2rem;
    line-height: 125%;
    color: var(--blue-main);
  }

  p {
    max-width: 88%;
    margin: 0 auto 28px;
    text-align: center;
    font-size: 1.8rem;
    line-height: 144%;
    color: #000;
  }

  @media screen and (max-width: $tablet) {
    padding: 2rem;
  }
}

.introForm {
  margin: 12px auto;

  :global {
    label {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: #0E0D28;

      &:before {
        content: none;
      }
    }

    input.ant-input {
      height: var(--input-height-xl);
    }
  }
}

.introNav {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 0 0;

  button {
    height: 46px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 1.8rem;

    &:first-of-type {
      background-color: rgba(51, 49, 177, 0.2);
      border-color: rgba(51, 49, 177, 0);

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        background-color: rgba(51, 49, 177, 0.4);
      }
    }
  }

  :global {
    .ant-btn-primary {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }
}

.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 3rem;
  background: var(--common-bg) !important;

  @include breakpoint(md) {
    margin: 0 30px;
  }

  @include breakpoint(sm) {
    margin: 0 30px;
  }

  .content {
    position: relative;
    display: grid;
    grid-template-columns: 550px 500px;
    gap: 90px;
    place-content: center;

    h2 {
      margin-top: -12px;
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 4.2rem;
      line-height: 1.4;
      color: #3331B1;
    }

    p {
      margin-bottom: 1px !important;
      font-size: 20px;
      line-height: 140%;
      font-weight: 400;
      color: #000000;
    }

    form {
      max-width: 580px;
      border-radius: 10px;
    }

    @media screen and (max-width: $desktop) {
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
    }

    @media screen and (max-width: 900px) {
      padding-top: 2.2rem;
      grid-template-columns: 1fr;
      grid-row-gap: 0px;

      h2 {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }

      p {
        margin-bottom: 25px;
        font-size: 15px;
      }

      form {
        max-width: 100%;

        :global {
          .ant-tabs .ant-tabs-content-holder {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    :global {
      .ant-spin-blur {
        opacity: 0.1;
      }

      .ant-tabs {
        margin-bottom: 0;

        .ant-tabs-nav {
          margin-bottom: 0;

          &:before {
            right: 8px;
            border-bottom-color: var(--common-intro-border);
          }

          .ant-tabs-nav-wrap {
            .ant-tabs-tab {
              position: relative;
              padding: 14px 36px;
              border-radius: 4px 4px 0 0;
              border-color: var(--common-intro-border);
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: var(--blue-hover);
              background-color: var(--common-intro-border);

              .ant-tabs-tab-btn {
                font-weight: 600;
                color: var(--blue-hover);
              }

              &.ant-tabs-tab-active {
                background-color: var(--common-intro-bg);
                border-bottom-color: var(--common-intro-bg);

                .ant-tabs-tab-btn {
                  color: var(--blue-main);
                  text-shadow: none;
                }
              }
            }

            .ant-tabs-ink-bar {
              background-color: var(--common-intro-bg);
            }
          }
        }

        .ant-tabs-content-holder {
          padding: 12px 20px;
          background-color: var(--common-intro-bg);
          border: 1px solid var(--common-intro-border);
          border-top: none;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;

          .ant-tabs-content {
            padding: 20px;
          }
        }
      }
    }
  }
}

.fullContent {
  position: relative;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr 595px 1fr;

  > div {
    grid-column: 2;
    text-align: center;
  }

  h2 {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 4.2rem;
    line-height: 143%;
    color: #3331B1;
  }

  p {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    margin: 0 auto 12px auto;
    font-size: 1.8rem;
    line-height: 156%;
    color: #000000;
    font-weight: normal;
  }

  .nav {
    width: 100%;
  }

  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr;

    p {
      left: 0;
      transform: translateX(0);
      width: 100%;
    }
  }
}

.nav {
  margin-top: 30px;
  width: 580px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .mobileBtn {
    display: none;
  }

  > div:first-of-type {
    text-align: left;
  }

  > div:last-child {
    grid-column: 3;
    text-align: right;

    :global {
      .ant-btn {
        width: 200px;
      }

      a.ant-btn {
        padding-top: 0.01px;
      }

      .ant-btn:not(.ant-btn-primary) {
        padding: 4px 16px !important;
        border: 2px solid var(--light-blue-grey);
        font-weight: 600;
        font-size: 16px;
        color: var(--dark-grey);

        &:hover,
        &:focus {
          color: var(--blue-hover);
          background-color: transparent;
          border-color: var(--light-border-blue);
        }
      }

      .anticon {
        color: var(--grey);
      }
    }
  }

  :global {
    .ant-btn {
      margin-bottom: 10px;
      height: 46px;
      font-weight: 600;
      font-size: 18px;
      line-height: 2;
      background-color: transparent;
      border-radius: 4px;

      &.ant-btn-primary {
        margin-bottom: 40px;

        .ant-btn-loading-icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 4px;
          margin: auto;
        }
      }

      &.ant-btn-sm {
        padding: 4px 24px;
      }
    }
  }

  @media screen and (max-width: $desktop) {
    width: 100%;
  }

  @media screen and (max-width: $tablet) {
    width: 100%;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: $mobile_full) {
    grid-template-columns: 1fr;

    .mobileBtn {
      display: block;
      border: 2px solid var(--blue-main) !important;
      color: var(--blue-main) !important;
      font-size: 18px !important;
      font-weight: 600 !important;
    }

    > div:first-of-type {
      display: none;
    }

    > div:last-child {
      grid-column: 1;
      text-align: left;
    }

    :global {
      .ant-btn {
        width: 100% !important;
        margin-bottom: 15px;

        &.ant-btn-primary {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.form {
  margin-top: 20px !important;
  :global {
    label {
      span > span {
        color: var(--easy);
      }
    }

    .ant-row:last-of-type {
      margin-bottom: 0;
    }

    .ant-select-selection-placeholder,
    .ant-input:placeholder-shown,
    .ant-picker input:placeholder-shown {
      color: #BDBDBD !important;
    }

    .anticon {
      display: inline-block;
      vertical-align: bottom;
      margin-left: 10px;
    }
  }

  &.partyForm {
    padding: 0;
    background-color: transparent;
    border: none;
  }
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;

  > div {
    margin-bottom: 12px;
  }

  h3 {
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0E0D28;

    span {
      font-weight: 400;
    }
  }
}

.disabledColumn {
  @extend .column;

  .productPicker {
    position: relative;
  }

  :global {
    .ant-form-item {
      width: 100%;

      &.ant-form-item-has-success:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }
    }

    .ant-picker.ant-picker-disabled,
    .ant-picker-input > input[disabled],
    textarea.ant-input.ant-input-disabled {
      padding: 0;
      opacity: 1;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: var(--dark-grey);
      background: transparent;
      border: none;
      cursor: text;
      resize: none;
    }

    .ant-picker-suffix {
      display: none;
    }
  }
}

.dynamicWrapper {
  position: relative;

  :global {
    .ant-row {
      + .ant-row {
        width: 100%;
      }
    }

    .ant-input,
    .ant-picker {
      border-radius: 6px;
    }

    .ant-btn.ant-btn-primary {
      width: 200px;
      height: 46px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--dark-grey);
      background-color: transparent;
      border: 2px solid #DAE1E9;
      border-radius: 4px;
      box-shadow: none;
      text-shadow: none;

      .anticon {
        margin: 0 0 2px 0;
        font-size: 12px;
      }

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        color: var(--blue-hover);
        background-color: transparent;
        border-color: var(--blue-hover);
      }
    }
  }
}

.productPicker {
  position: relative;
}

.productTextArea {
  position: relative;
}

.dynamicNav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 0 0 0;

  > button {
    position: relative;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: var(--dark-grey);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:not([disabled]):hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

.linkToFullContract {
  font-size: 14px !important;
  font-style: italic;
  line-height: 17px;
  margin-top: 13px;
  .link {
    cursor: pointer;
    background: rgba(51, 49, 177, 0.2);
    border-radius: 4px;
    color: var(--blue-main);
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    padding: 9px 8px;
    margin-left: 5px;
    &:hover {
      color: var(--blue-main);
    }
  }
}
