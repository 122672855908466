%menuWrapper {
    //position: absolute;
    //top: 0;
    width: 180px;
    background-color: #fff;
    border: 1px solid var(--light-blue-grey);
    border-bottom: none;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

%menuItem {
    margin: 0;
    font-family: var(--font_inter);
    font-weight: 600;
    font-size: 14px;
    color: var(--dark-grey);
    border-bottom: 1px solid var(--light-blue-grey);

    &:hover {
        color: var(--blue-headline);
    }

    svg {
        max-width: 16px;
        height: auto;
        margin-left: 10px;
        vertical-align: middle;
    }
}

.wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 794px; // 270px
    grid-column-gap: 10%;
    justify-content: space-between;
    align-items: flex-start;

    .column {
        position: relative;
        display: flex;
        flex-direction: column;

        > h2 {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: var(--grey);
        }

        > button {
            min-width: 150px;
            height: 44px;
            margin-top: 15px;
        }

        &:first-of-type {
            z-index: 9;
            position: -webkit-sticky;
            position: sticky;
            top: calc(var(--header-height) + 12px);
            bottom: 0;
            height: calc(100vh - (var(--header-height) + 22px));
            background-color: var(--common-bg);
            overflow-y: auto;
        }
    }

    .editor {
        position: relative;

        > button {
            z-index: 99;
            position: absolute;
            top: 0;
            right: 0;
        }

        :global {
            .quill {
                z-index: 8;
                position: relative;
                //display: grid;
                //grid-template-rows: 42px 1fr;
                //grid-row-gap: 4px;
                height: inherit;
            }

            .ql-toolbar.ql-snow,
            .ql-container.ql-snow {
                border: none;
            }

            .ql-toolbar {
                position: relative;
                z-index: 9;

                //position: -webkit-sticky;
                //position: sticky;
                //top: var(--header-height);

                &--fixed {
                    position: fixed;
                    top: 105px;
                    background-color: var(--common-bg);
                    transform : translate3d(0, 0, 0);
                    box-shadow: rgba(60, 64, 67, 0.15) 0px 2px 5px -1px;
                }
            }

            .ql-editor {
                position: relative;
                max-width: 794px;
                min-height: 1056px;
                // margin: 3.75pt 3.75pt 7.5pt;
                padding: 35pt 35pt;
                cursor: text;
                //overflow: hidden;
                overflow: visible;
                background-color: var(--white);
                -webkit-tap-highlight-color: initial;
                border: 0px;
                box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                outline: transparent solid 1px;
                color: black;
                white-space: normal;
                font-family: var(--font_verdana);
                font-weight: 500;
                font-size: 16px;
                line-height: 1.5;

                br {
                    display: inline-block;
                    width: 100%;
                    height: 24px;
                }

                h1, h2, h3, h4, h5, h6 {
                    font-weight: 600;
                }

                .quill-field {
                    display: inline-block;
                    cursor: pointer;

                    &.quill-field-disabled {
                        cursor: default;
                        user-select: none;
                    }
                }

                .quill-divider {
                    position: relative;
                    bottom: 0;
                    left: 50%;
                    margin: 28px auto;
                    width: calc(100% + 94px);
                    height: 24px;
                    transform: translateX(-50%);
                    background-color: var(--common-bg);
                    border: none;
                    box-shadow: rgba(60, 64, 67, 0.15) 0px 3px 3px -2px,
                    rgba(60, 64, 67, 0.15) 0px -3px 3px -2px;
                }

                .quill-table {
                    table-layout: fixed;
                    border-collapse: collapse;
                    border-spacing: 0;
                    width: 100% !important;
                    max-width: 100% !important;
                    border: 1px dashed black;

                    tr {
                        height: 33px;
                    }

                    th, td {
                        text-align: left;
                        padding: 4px;
                        border: 1px dashed black;

                        img {
                            vertical-align: sub;
                        }
                    }
                }
            }

            .ql-toolbar button {
                &.ql-divider {
                    background: center center no-repeat url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='113.28px' height='113.281px' viewBox='0 0 113.28 113.281' style='enable-background:new 0 0 113.28 113.281;'%3E%3Cg%3E%3Cg%3E%3Cpath d='M80.872,3.471l-60.903,98.662c-2.122,3.436-1.055,7.938,2.38,10.057c1.196,0.738,2.521,1.092,3.832,1.092 c2.451,0,4.846-1.231,6.227-3.472l60.903-98.663c2.121-3.435,1.055-7.937-2.381-10.056C87.496-1.029,82.99,0.036,80.872,3.471z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-size: 16px;

                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='113.28px' height='113.281px' viewBox='0 0 113.28 113.281' style='enable-background:new 0 0 113.28 113.281;'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%2306c' d='M80.872,3.471l-60.903,98.662c-2.122,3.436-1.055,7.938,2.38,10.057c1.196,0.738,2.521,1.092,3.832,1.092 c2.451,0,4.846-1.231,6.227-3.472l60.903-98.663c2.121-3.435,1.055-7.937-2.381-10.056C87.496-1.029,82.99,0.036,80.872,3.471z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    }
                }
            }

            .ant-menu {
                z-index: 11;
                position: relative;
                @extend %menuWrapper;
            }

            .ant-menu-vertical > .ant-menu-item,
            .ant-menu-vertical-left > .ant-menu-item,
            .ant-menu-vertical-right > .ant-menu-item,
            .ant-menu-inline > .ant-menu-item,
            .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
            .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
            .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
            .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
                @extend %menuItem;
            }
        }
    }
}

:global {
    .ant-menu-submenu-popup {
        .ant-menu-sub {
            @extend %menuWrapper;
        }

        .ant-menu-vertical > .ant-menu-item,
        .ant-menu-vertical-left > .ant-menu-item,
        .ant-menu-vertical-right > .ant-menu-item,
        .ant-menu-inline > .ant-menu-item,
        .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
        .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
        .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
        .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
            @extend %menuItem;
        }
    }

    .ant-form {
        .ant-checkbox-checked::after {
            border-color: var(--blue-main);
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--blue-main);
            border-color: var(--blue-main);
        }
    }
}
