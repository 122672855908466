.table {
  background-color: var(--white);
  max-width: 100%;
  margin: 0 6%;
  margin-bottom: 3em;

  thead {
    height: 0;
    tr > th:first-child {
      padding-left: 86px;
    }
  }
  tbody > tr > td:first-child {
    padding-left: 86px;
  }

  .tableFind {
    display: flex;
    gap: 2rem;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .tableActions {
    width: 15%;
  }

  .tablePosition {
    position: relative;
    tr:hover {
      background-color: var(--selector-item);
    }
    .statusMarker {
      color: var(--notification-color);
    }
    .title:active,
    .title:hover {
      text-decoration: underline;
    }

    .tableStatus {
      border-radius: 1rem;
      padding: 7px 15px;
      margin-right: 2rem;
      min-width: max-content;
      display: flex;
      align-items: center;

      p {
        padding-left: 0.4rem;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }

      .grow {
        flex-grow: 1;
      }
    }

    .tableCursor {
      cursor: pointer;
    }
  }

  .tableDivPosition {
    display: flex;
    flex-direction: column;

    .tableDropdown {
      display: flex;
      margin: 0.5rem 0;

      .iconMargin {
        padding: 0 0.5rem;
      }

      .subText {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.46);
      }

      .iconDisplay {
        display: none;
      }

      .iconSigned {
        color: #56bb6a;
      }
    }
  }

  .search input {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .actionButtom {
    max-width: 100px;
    height: 40px;
    font-weight: 400;
    border-color: var(--light-grey);
    color: var(--new-dark-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: var(--new-dark-grey);
    }
  }
  .actionButtomCaret {
    display: flex;
    margin-left: 6.5px !important;
  }
}

.paginationContainer {
  text-align: right;
  max-width: 100%;
  margin: 44px 6% 0 6%;
  position: relative;
  .pagination {
    display: inline-block;
    border: 1px solid var(--new-line-gray);
    border-radius: 14px;
    height: 51px;
    margin-right: 61px;
    :global {
      .ant-pagination-next,
      .ant-pagination-item {
        align-items: center;
        background-color: transparent;
        border-radius: 0;
        border-radius: 14px;
        border: none;
        color: var(--new-black);
        display: inline-flex;
        height: 49px;
        justify-content: center;
        margin-right: 0;
        width: 51px;
        &:hover a {
          color: var(--new-black);
        }
      }
      .ant-pagination-item-link-icon {
        color: var(--purple) !important;
      }
      .ant-pagination-prev {
        display: none;
      }
      .ant-pagination-item-active {
        background-color: var(--purple);
        border-color: transparent;
        &:hover a,
        a {
          color: var(--white);
        }
      }
      .ant-pagination-next {
        border: 1px solid var(--new-line-gray);
        position: absolute;
        right: 0;
        svg {
          fill: var(--purple);
        }
      }
    }
  }
}

.dropDown {
  border: 1px solid var(--light-blue-grey);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  pointer-events: auto;

  li {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
    color: var(--dark-grey);

    > a {
      padding: 5px 12px !important;
      color: var(--dark-grey) !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
