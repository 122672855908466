@import '../../../styled/breakpoints';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: var(--common-bg) !important;
}

.header {
  align-items: center;
  background: var(--white);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;

  @include breakpoint(sm) {
    padding: 20px;
  }

  .contractTitle {
    color: var(--blue-headline);
    font-size: 2rem;
    font-weight: bold;
    line-height: 133%;
    text-align: center;
    @include breakpoint(sm) {
      font-size: 2.1rem;
      line-height: 1;
      max-width: 90%;
    }
  }
}


.mainContainer {
  background-color: var(--common-bg);

  .detailsContainer {
    margin: 0 auto;
    text-align: center;
    max-width: 1012px;
    padding: 31px 0 49px 0;
    @include breakpoint(sm) {
      padding: 8px 0 22px 0;
    }

    .title {
      color: var(--blue-main);
      font-size: 3.5rem;
      font-weight: 700;
      line-height: 0.8;
      margin-bottom: 31px;
      text-align: center;
      @include breakpoint(sm) {
        font-size: 3.3rem;
        margin-bottom: 24px;
      }
    }
    .paragraph {
      color: #000;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 156%;
    }
  }

  .tabs {
    margin-right: 7px;
    margin-bottom: 15px;
  }

  @include breakpoint(sm) {
    padding: 0 20px;
  }
}


.stikyContainer {
  width: 400px;
  margin: 0 120px;
  position: sticky;
  top: 125px;
  padding-bottom: 1px;
  @include breakpoint(sm) {
    width: unset;
    margin: unset;
  }
}

.orderOfContract {
  margin: 0 30px;
  display: grid;
  grid-template-columns: 500px 800px;
  grid-gap: 70px;

  @include breakpoint(sm) {
    grid-template-columns: 1fr;
    gap: unset !important;
  }
}
