@import '../../../../styled/breakpoints';

.mainContainer {
  margin-bottom: 40px;
  min-height: 192px;
  @include  breakpoint(sm){
    margin-bottom: 21px;
  }
  h2 {
    margin-bottom: 18px;
  }

  .uploadContent {
    display: flex;
    flex-direction: column;
  }
  .logoUpdated,
  .fileTooLong {
    bottom: -4px;
    font-size: 10px;
    font-weight: 400;
    height: 28px;
    line-height: 1.2;
    margin-left: -1px;
    margin-right: -1px;
    padding: 2px 10px 2px 20px;
    position: relative;
    text-align: left;
    &::before {
      content: '';
      display: inline-block;
      height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 6px;
    }
  }
  .logoUpdated {
    background-color: #bfdbba;
    color: #0c5000;
    &::before {
      background: #0c5000;
    }
  }
  .fileTooLong {
    background-color: #FFDFDE;
    color: #B81F2D;
    &::before {
      background: #B81F2D;
    }
  }
}

.content {
  .rowUpload{
    @include  breakpoint(sm){
      justify-content: center !important;
    }

  }
  .rowOptions{
    @include  breakpoint(sm){
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 12px;
    }
  }
  .uploadButton {
    align-self: center;
    background-color: var(--light-blue-grey);
    border-radius: 4px;
    border: none;
    color: rgba(14, 13, 40, 0.5);
    font-size: 11px;
    font-weight: 500;
    height: 18px;
    line-height: 11px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 4px 13px;
    @include  breakpoint(sm){
      font-size: 10px;
    }
  }
  .helpText {
    color: rgba(14, 13, 40, 0.5);
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    margin: 15px auto 0 auto;
    max-width: 210px;
    text-align: center;
    @include  breakpoint(sm){
      display: none !important;
      font-size: 8px;
      line-height: 1.5;
    }
  }
  .showTextTop {
    display: none;
    @include  breakpoint(sm){
      display: inherit !important;
    }
  }
  .placement {
    color: var(--gray-tr);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  .radioGroup {
    @include breakpoint(sm){
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    label {
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      color: rgba(14, 13, 40, 0.5);
      margin-bottom: 6px;
      margin-right: 0;
      @include breakpoint(sm){
        color: rgba(14, 13, 40, 0.8);
      }
      svg {
        vertical-align: middle;
      }
    }
    :global {
      .ant-radio-inner {
        width: 11px;
        height: 11px;
        &::after {
          width: 7px;
          height: 7px;
          top: 1px;
          left: 1px;
          background-color: var(--blue-main);
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: var(--blue-main);
      }
      .ant-radio {
        top: 0;
      }
      .ant-radio-wrapper {
        align-items: inherit;
      }
    }
  }

  :global {
    .ant-upload {
      border-radius: 4px;
      display: flex !important;
      justify-content: center;
      min-height: 75px;
      width: 100%;
      margin-right: 23px;
      flex-direction: column;
      max-width: 123px;
      @include  breakpoint(sm){
        height: 73px;
        margin: 0 auto;
        width: 129px;
        align-items: center !important;
      }
      
      span.ant-upload {
        border: 0.5px dashed rgba(25, 23, 125, 0.4);
      }
      button {
        max-width: 86px;
        padding: 2px 7px;
      }
    }
  }
}

:global {
  .ant-upload-list {
    display: none;
  }
}