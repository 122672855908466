@import "../../styled/breakpoints";

:global {
    .modal {
        min-width: 560px !important;
        @include breakpoint(sm){
            min-width: auto !important;
            max-width: 85% !important;
        }
        .ant-modal-content {
            .ant-modal-close {
                display: block;
            }
            .ant-modal-header {
                background-color: #F5F4FF;
                padding: 15px !important;
                .ant-modal-title {
                    color: var(--blue-headline);
                    font-size: 2.4rem !important;
                    @include breakpoint(sm){
                        font-size: 3.2rem !important;
                    }
                }
            }
            .ant-modal-body {
                font-size: 1.6rem;
                max-width: 472px;
                margin: 0 auto;
                padding: 29px 42px 0 42px;
                color: #050A1A;
                box-sizing: content-box;
                text-align: center;
                @include breakpoint(sm) {
                    font-size: 2.1rem;
                    line-height: 23px;
                    font-weight: 400;
                }
            }
            .ant-modal-body p {
                overflow-wrap: break-word;
            }
            .ant-modal-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                @include breakpoint(sm){
                    flex-direction: column-reverse;
                    padding-bottom: 33px;
                }
                .ant-btn {
                    display: flex;
                    justify-content: center;
                    line-height: 16px;
                    width: 100% !important;
                    height: 100% !important;
                    padding-top: 14px !important;
                    padding-bottom: 14px !important;
                    min-width: 199px !important;

                    &:first-child {
                        color: var(--blue-main);
                        background-color: var(--blue-main-20);
                        border-color: transparent;
                    }

                    &:last-child {
                        max-width: fit-content;
                        background-color: var(--blue-main) !important;
                        border-color: var(--blue-main) !important;
                        @include breakpoint(sm){
                           margin-left: 0px !important;
                           margin-bottom: 10px;
                        }
                    }

                }
            }
        }
    }
}
