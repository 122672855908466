@import "../../../../styled/breakpoints";

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 84px;

    @include breakpoint(sm) {
        align-items: center;
        margin-bottom: 0px;
    }

    button {
        border: 2px solid var(--light-blue-grey) !important;
        height: 46px;
        margin-bottom: 10px;
        min-width: 200px;
        color: var(--dark-grey) !important;
        background-color: transparent;
        font-weight: 500 !important;

        @include breakpoint(sm) {
            width: 100% !important;
            height: 55px;
            font-size: 18px !important;
        }
        &.save {
            border: none;
            color: var(--white) !important;
        }
    }

    .exitButton span {
        margin-left: 8px;
    }

    .firstRow {
        @include breakpoint(sm) {
            justify-content: center;
            flex-direction: column-reverse;
        }
        width: 100%;
    }
    .saveContainer {
        text-align: left;
        &.second {
            text-align: right;
        }
        @include breakpoint(sm) {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.btnModal {
    @include breakpoint(sm) {
        width: 100% !important;
        margin-left: 0px !important;
    }
}

:global {
    .ant-modal-footer {
        @include breakpoint(sm) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.text_modal {
    text-align: center !important;
}
