@import '../../../styled/breakpoints';

.contractButton {

  @include breakpoint(sm) {
    margin-inline-start: unset !important;
  }

  :global {
    .ant-btn {
      background: var(--contract-button);
      color: var(--white);
      border: 1px solid var(--bg-mainContainer);
      display: flex;
      align-items: center;
      padding: 2.2rem 2rem;
      border-radius: 14px !important;
      width: 182px;
      height: 50px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      @include breakpoint(sm) {
        svg {
          transform: translateX(12px) !important;
          margin-right: 20px;
        }
      }

      &:hover {
        background: var(--white);
        color: var(--contract-button);
        border: 1px solid;

        svg path {
          fill: var(--contract-button);
        }
      }

      &:focus,
      &:active,
      &:hover {
        background-color: var(--contract-button) !important;
        color: var(--white) !important;

        svg path {
          fill: var(--white) !important;
        }
      }
    }
  }

  .sheetIcon {
    margin-right: 9px;
  }
}

:global {
  .ant-dropdown {
    .ant-menu {
      padding: 20px 32px;
      width: 214px;
      border-radius: 6px;
      max-height: 237px;
      overflow-y: auto;

      .ant-menu-item {
        padding: 0 !important;
        margin: 0 !important;
        border: none;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 400;
        color: var(--black-secondary);
      }
    }
  }
}
