@import "../../../styled/breakpoints";

.tabs {
    position: relative;
    @include breakpoint(sm) {
        margin-bottom: 0px !important;
    }

    :global {
        .ant-tabs-nav {
            margin-bottom: 0 !important;
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            position: absolute;
            top: 0;
            background-color: transparent;
        }

        .ant-tabs-tab {
            margin-right: 0;
            padding: 2px 27px;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.77;
            color: var(--blue-main);
            border: 1px solid transparent;
            border-bottom: none;
            transition: all 0.2s ease-in-out;
            background-color: var(--common-intro-border);
            border-radius: 4px 4px 0 0;
            @include breakpoint(sm) {
                font-size: 1.7rem;
                height: 31px;
            }

            & + .ant-tabs-tab {
                margin-left: 2px;
            }

            &:focus,
            &:active,
            &:hover {
                color: var(--blue-headline);
            }

            .ant-tabs-tab-btn a {
                &:focus,
                &:active,
                &:hover {
                    color: var(--blue-headline);
                }
            }

            &.ant-tabs-tab-active {
                background-color: var(--common-intro-bg);
                border-color: var(--lightest-blue);

                &:before {
                    background-color: var(--common-intro-bg);
                    border: none !important;
                    bottom: -2px;
                    content: " ";
                    height: 2px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                }

                .ant-tabs-tab-btn {
                    color: var(--blue-headline);
                    font-weight: 500;
                }
            }
        }

        .ant-tabs-nav-wrap {
            overflow: visible !important;
            width: 100%;
        }

        .ant-tabs-content-holder {
            border: 1px solid var(--common-intro-border);
            border-radius: 0 8px 8px 8px;
            padding: 24px 22px 3px 22px;
            background-color: var(--common-intro-bg);
        }

        .ant-form-item {
            margin-bottom: 16px;
            .ant-form-item-label {
                padding: 0 0 6px;
                label {
                    @include breakpoint(sm) {
                        font-size: 12px !important;
                    }
                }
            }
            .ant-input {
                height: 34px !important;
                @include breakpoint(sm) {
                    font-size: 13px !important;
                }
                &.ant-input.ant-input-disabled {
                    background-color: var(--common-intro-bg);
                    border: none;
                    color: #979797;
                }
            }
        }
    }

    .address {
        min-height: 58px;
        @include breakpoint(sm) {
            min-height: 20px;
        }
    }

    .missinFieldsWarning {
        color: #ff4d4f;
        padding-bottom: 12px;
    }

    &.disabled {
        :global {
            .ant-form-item {
                .ant-form-item-control {
                    @include breakpoint(sm) {
                        margin-top: -12px;
                    }
                }
                .ant-input {
                    @include breakpoint(sm) {
                        height: 20px !important;
                    }
                }
            }
        }
    }
}

.title {
    color: var(--blue-hover);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
    text-align: left;
    @include breakpoint(sm) {
        font-size: 14px;
        margin-bottom: 11px;
        margin-top: 20px;
        text-align: center;
    }
}
