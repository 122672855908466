.table {
  background-color: var(--white);
  max-width: 100%;
  margin: 0 6%;
  margin-top: 30px;
  margin-bottom: 3em;

  thead {
    height: 0;

    tr>th:first-child {
      padding-left: 86px;
    }
  }

  tbody>tr>td:first-child {
    padding-left: 86px;
  }

  tbody>tr:hover {
    background-color: var(--selector-item);
  }

  .tableCategory {
    font-size: 16px;
    background-color: var(--category-table);
    padding: 2.3rem 0 2rem 0;
  }

  .tableSwitch {
    border: 0.5px solid rgba(216, 222, 232, 1);
    background-color: var(--white);
  }

  input {
    box-shadow: var(--input-shadow);
    border: 1px solid var(--light-blue);
    border-radius: 8px;
    height: 3rem;
    padding-left: 1rem;
    outline-color: var(--purple);
  }

  .inputUrl {
    width: 80%;
    margin-left: 2rem;
  }

  :global {
    [aria-checked="true"] {
      .ant-switch-handle::before {
        background-color: var(--category-switch);
      }
    }

    [aria-checked="false"] {
      .ant-switch-handle::before {
        background-color: var(--category-switch-off);
      }
    }

    .ant-switch-checked {
      background-color: var(--white);
    }
  }

  .tablePosition {
    position: relative;

    .categoryText {
      font-size: 15px;
    }

    .statusCnt {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-left: 24px !important;
      }
    }
  }
}
