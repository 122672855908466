@import "../../../styled/breakpoints";

.headerBar {
  background-color: var(--bg-mainContainer);
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 6%;

  .searchTitle {
    .stack {
      margin-right: 20px;
    }

    .newProjectTitle {
      border-right: 4px solid #c4c4c4;
      padding-right: 4px;
    }

    .placeholderTitle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 41px;
      display: flex;
      align-items: center;

      color: rgba(193, 193, 193, 0.6);
    }
  }

  .searchTitle,
  .searchTitle input {
    font-size: 24px;
    line-height: 50px;
    color: var(--violet);
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-grow: 1;

    h1 {
      color: inherit;
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      display: flex;
      align-items: center;
    }
  }

  .searchTitle input {
    font-weight: bold;
  }

  .searchTitle .iconContainer {
    display: none;
    border-radius: 100%;
    border: 1px solid var(--header-icons);
    align-items: center;
    justify-content: center;
    width: 2.7rem;
    height: 2.7rem;
    margin-left: 1rem;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      color: var(--header-icons);
    }
  }

  .archiveIcon {
    margin-left: 18px;
  }

  .iconContainer:hover {
    border: 1px solid var(--violet);

    svg {
      color: var(--violet);
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 11px;
    color: var(--header-icons);

    .templateButton {
      :global {

        .ant-btn {
          background: var(--violet-light);
          color: var(--white);
          border-color: var(--violet-light);

          &:hover {
            background: var(--white);
            color: var(--violet-light);
            border: 1px solid;

            svg path {
              fill: var(--violet-light);
            }
          }
        }
      }
    }

    .categoryButton {
      :global {
        .ant-btn {
          background: var(--contract-button);
          color: var(--white);
          border-color: var(--contract-button);

          &:hover {
            background: var(--white);
            color: var(--contract-button);
            border: 1px solid;

            svg path {
              fill: var(--contract-button);
            }
          }
        }
      }
    }

    button {
      border-radius: 14px;
      width: 182px;
    }

    input {
      width: 24rem;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.header {
  background-color: var(--bg-mainContainer) !important;
  height: 127px !important;
  max-width: 100% !important;
}

.headerMobile {
  max-width: 100% !important;
  background-color: var(--violet) !important;
  line-height: unset !important;
  height: 80px !important;
  padding: 0 !important;
}

.inputSearch {
  width: 411px;
  min-width: 411px;
  max-width: 411px;
}
