@import "../../../styled/breakpoints";

.badgeFilled {
  position: relative !important;

  svg {
    margin-left: 0 !important;
  }

  sup {
    position: absolute !important;
    width: 12px !important;
    border-radius: 50% !important;
    left: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.badgeFilled,
.badge {
  min-width: fit-content;
  cursor: pointer;
  sup {
    background-color: var(--blue-main);
    top: -10px;
    left: -10px;
  }

  :global {
    .anticon svg path {
      fill: var(--new-grey);
    }

    @include breakpoint(sm) {
      .ant-badge-count {
        position: absolute !important;
        top: -5px;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        left: 12px !important;
        width: 13px !important;
        min-width: 13px !important;
        height: 13px !important;
        font-size: 7px !important;
      }
    }
  }
}

.badgeEmpty {
  margin-right: 12px !important;
}

.cardNotificationMobile {
  max-height: 295px;
  overflow-y: auto;
}

.cardNotificationDesktop {
  max-height: 300px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: var(--white);
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar);
}
