@import "../../../styled/breakpoints";

.contentContainer {
  .menuItem {
    margin: 0 !important;
    border: none !important;
  }

  .submenu {
    border: none !important;

    svg {
      margin-right: 10px !important;
    }
  }
}

:global {
  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 0 !important;
    }

    border-radius: 6px !important;

    .ant-menu {
      border-radius: 6px !important;
      padding: 7px 0;

      .ant-menu-item {
        vertical-align: bottom;
        height: 100%;
        background: var(--white) !important;

        &:hover {
          background: var(--light-blue-l) !important;
        }
      }
    }
  }

  .ant-menu-sub {
    width: 250px !important;
    border-radius: 6px !important;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1) !important;
    left: -70px;
    @include breakpoint(sm) {
      left: unset;
    }
  }

  .ant-menu-submenu-open,
  .ant-menu-submenu-active {
    background: var(--light-blue-l) !important;
  }

  .ant-menu-submenu .ant-menu-item {
    padding: 5px 10px;
    margin: 0 !important;
    border: none !important;
    height: 100% !important;

    &:hover {
      background-color: var(--light-blue-l) !important;
    }

    p {
      margin-top: -20px;
      font-weight: 400;
    }
  }

  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    border: none !important;
  }
}

.selectItem {
  text-align: center;

  &:nth-child(1) {
    background-color: var(--violet) !important;
    color: var(--white) !important;
    padding: 5px !important;
    border-radius: 6px 6px 0 0;
  }

  &:not(&:nth-child(1)) {
    color: var(--white) !important;
    background-color: var(--violet-pale) !important;
    border-bottom: 1px solid var(--common-intro-bg) !important;
    padding: 2px;

    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
}

.modal {
  position: relative;
  text-align: center;

  :global {
    .ant-modal-footer {
      text-align: center;
    }
  }
}

.itemInput {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px !important;
  position: relative;

  input {
    border-radius: 8px 0 0 8px;
    height: 40px !important;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    color: var(--white) !important;
    border-radius: 0 8px 8px 0 !important;
    height: 40px !important;
    background: var(--violet) !important;

    svg {
      transform: translateX(-5px);
    }
  }
}

.modalDelete {
  &:nth-child(1) {
    width: 70% !important;
    margin: auto !important;
  }
}

.deleteTextContainer {
  margin-bottom: -15px;

  .deleteTextModal {
    text-align: center !important;
    margin: auto;
    line-height: 23px !important;
    width: 208px !important;
    font-size: 18px !important;
    color: var(--base-black) !important;
  }
}

.buttonCancel,
.buttonDelete {
  background-color: var(--blue-main) !important;
  color: var(--white) !important;
  height: 50px !important;

  &:focus,
  &:hover {
    background-color: var(--blue-main) !important;
    color: var(--white) !important;
  }
}

.buttonCancel {
  background-color: var(--cancel-button) !important;
  border: none !important;
  color: var(--blue-main) !important;
  font-weight: 600 !important;
  margin-left: -1px !important;
}
