@import "../../../styled/breakpoints";

:global {
    .SendForReviewModal {
        .ant-modal-content {
            .ant-modal-title {
                color: var(--base-black);
                font-size: 1.7rem !important;
            }

            .ant-modal-close {
                font-size: 19px;
                font-weight: bold;
                display: block;
                margin-top: -10px;
            }

            .ant-modal-header {
                background-color: var(--common-intro-bg);
                padding: 35px 20px 20px 20px;
                height: 103px;
                @include breakpoint(sm) {
                    padding: 30px 50px 16px 50px;
                    height: auto;
                }
            }

            .ant-modal-body {
                padding: 25px 42px 27px 58px;
                @include breakpoint(sm) {
                    padding: 23px 17px 21px 17px;
                }
            }

            .ant-modal-footer {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 0;
                padding-bottom: 24px;
                @include breakpoint(sm) {
                    padding: 0 42px 42px 42px;
                }

                .ant-btn {
                    display: flex;
                    height: 40px !important;
                    justify-content: center;
                    line-height: 16px;
                    padding-bottom: 14px !important;
                    padding-top: 14px !important;
                    width: 173px !important;
                    min-width: 173px !important;
                    background-color: red !important;

                    &:last-child {
                        min-width: 150px;
                        color: var(--white) !important;
                        background-color: var(--orange) !important;
                        border-color: var(--orange) !important;
                    }

                }
            }
        }
    }

    .custom-header {
        p {
            font-weight: 700;
            color: var(--base-black);
            font-size: 1.7rem;
            line-height: 1.2;
            @include breakpoint(sm) {
                font-size: 17px;
            }
            
            span {
                display: inline-block;
                font-weight: 400;
            }
        }
    }
}


.form {
    :global {
        .email {
            margin-bottom: 9px;
            background-color: #F3F7FE !important;
            border-color: #F3F7FE !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 8px !important;
            padding-right: 16px;

            &.ant-input {
                padding: 17px 32px !important;
                @include breakpoint(sm) {
                    height: 54px !important;
                    margin-bottom: 4px;
                }
            }

            input {
                padding: 12px 16px 12px 21px !important;
                background-color: #F3F7FE !important;
                border-color: #F3F7FE !important;
            }

            &::placeholder, & input::placeholder {
                font-weight: 600;
                font-size: 16px;
                color: #AAAAAA !important;
            }
        }

        .ant-input-affix-wrapper {
            height: 54px !important;
        }
    }

    .remove {
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        color: #AAAAAA;
    }

    .addEmail {
        color: #AAAAAA;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        margin-left: 32px;
        margin-top: 8px;
        text-align: left;
    }
}
