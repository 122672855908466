.siderBarContainerCollapsed {
  max-width: 66px !important;
  min-width: 66px !important;
  width: 66px !important;
  background-color: var(--violet) !important;

  .siderBar {
    display: flex;
    flex-direction: column;

    .siderTitle {
      height: 100%;
      padding-top: 42px;
      padding-left: 28px;
      padding-right: 29px;
      width: 66px;
      background: var(--violet);
      color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      flex-direction: row;
      position: relative;

      .arrow {
        position: absolute;
        right: 5px;
        transition: all 0.3s ease !important;
      }

      .arrowDown {
        margin-top: -10px;
      }

      .arrowRight {
        transform: rotate(180deg);
      }

      .icon {
        border-radius: 100%;
        background: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
      }
    }
  }
}

.siderBarContainer {
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;
  margin-top: 22px !important;

  .siderBar {
    display: flex;
    flex-direction: column;

    .siderTitle {
      padding-top: 0;
      background: var(--violet);
      color: var(--white);
      height: 78px;
      display: flex;
      display: flex;
      align-items: center;
      padding-left: 37px;

      h2 {
        color: inherit;
        margin-left: 10px;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
      }

      .icon {
        width: 28px;
        height: 28px;
      }
    }


    .siderButton {
      align-items: center;
      display: flex;
      margin-top: 30px;
      margin-bottom: 18px;
      margin-left: 37px;

      :global {
        .ant-btn {
          background: var(--violet-light);
          color: var(--white);
          border: 1px solid;
          display: flex;
          width: 182px !important;
          height: 40px !important;
          align-items: center;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
          border-radius: 10px;
          text-align: center;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
        }

        .ant-btn:hover {
          background: var(--white);
          color: var(--violet-light);
          border: 1px solid;

          svg path {
            fill: var(--violet-light);
          }
        }

        .ant-btn:disabled {
          background: var(--disabled-button);
          color: var(--white);
          border: 1px solid;

          svg path {
            fill: var(--white);
          }
        }
      }

      svg {
        width: 20px;
        height: 26px;
      }
    }

    .menu {
      text-align: center;
      border: none;
      width: 260px;

      .newProjecItem {
        height: 60px;
        padding-inline-start: 66px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .ant-input {
          border: 0px solid #d9d9d900;

          &:focus {
            border: 0px solid #d9d9d900;
            border-color: var(--violet);
          }
        }

        input {
          height: 23px;
          border: 0px;
          border-left: 3px solid #c4c4c4;
          border-radius: 0px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;

          color: #6418c3;

          &:focus {
            border: 0px solid #d9d9d900;
            box-shadow: none !important;
            height: 23px;
            border: 0px;
            border-left: 3px solid #c4c4c4;
            border-radius: 0px;
          }

          &:placeholder {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: rgba(203, 203, 203, 0.6);
          }

          &:before {
            width: 10px;
            background-color: #c4c4c4;
          }
        }
      }

      .newProjectCtn {
        padding-left: 140px !important;
        text-align: start;

        &:before {
          padding-right: 0px !important;
        }

        &:after {
          content: "";
          position: absolute;
          right: 0;
          width: 6px;
          height: 100%;
          background-color: white !important;
          border-radius: 25px !important;
        }
      }

      .itemDrop {
        box-shadow: 6px 4px 4px 1px rgba(0, 0, 0, 0.05) !important;

        &:nth-child(1) {
          height: 60px !important;
        }

        p {
          color: var(--violet) !important;
          font-weight: 700 !important;
        }
      }

      :global {
        .project-item {
          position: relative;
          margin: 0 !important;
          width: 260px;
          height: 60px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          &:hover {
            cursor: pointer;

            &:after {
              content: "";
              position: absolute;
              right: 0;
              width: 6px;
              height: 100%;
              background-color: var(--violet);
              border-radius: 25px !important;
            }
          }
        }


        .project-item-general {
          position: fixed !important;
          background: var(--white);
          width: 100% !important;
          left: 0 !important;
          z-index: 999999;
        }



        .project-item-general,
        .project-item-selected {
          box-shadow: 6px 4px 4px 1px rgba(0, 0, 0, 0.05) !important;
          position: relative;
          margin: 0 !important;
          width: 260px;
          height: 60px;

          .project-title,
          .project-title-archive {
            color: var(--violet) !important;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            width: 6px;
            height: 100%;
            background-color: var(--violet);
            border-radius: 25px !important;
          }
        }

        .ant-menu-sub a:hover {
          color: var(--base-black) !important;
        }

        .ant-menu-submenu {
          border: none;
        }

        .ant-menu-sub {
          right: 0px;
          background: var(--white);
        }

        .ant-menu-item-selected {
          background: var(--white);
          color: var(--violet);
          box-shadow: 6px 4px 4px 1px rgba(0, 0, 0, 0.05);

          .selected {
            background: var(--white);
          }
        }

        .ant-menu-item::after {
          border-right: 6px solid var(--violet);
          border-radius: 25px;
        }

        .ant-menu-inline>.ant-menu-item {
          border: none;
        }

        .ant-menu-inline {
          border: none;
        }

        [role="project"] {
          border: none;
          display: flex;
          align-items: center;
          padding-left: 37px !important;

          p {
            color: var(--header-icons);
            text-align: left;
          }

          .container-items {
            position: relative !important;
            display: flex;
            align-items: center;
            transform: unset !important;

            .container-icons {
              display: inline-flex;
              align-items: center;
              gap: 20px;
              visibility: hidden;
              position: relative;
              margin-right: 13px;

              .dnd-icon {
                display: none;
              }
            }
          }

          .project-title-archive {
            color: var(--header-icons);
            margin-left: 30px;
            width: 260px !important;
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          &.ant-menu-item-selected,
          &:hover {
            .container-icons {
              visibility: visible;
            }
          }
        }

        [role="archived"] {
          border-top: 1px solid var(--light-blue-grey) !important;
          border-bottom: none;

          .anticon svg {
            visibility: hidden;
          }

          &.ant-menu-item-selected svg path {
            fill: var(--violet);
          }

          &.ant-menu-item-selected {
            &.projectMainItemNotSelected {
              color: var(--new-grey);

              svg path {
                fill: var(--new-grey);
              }

              &:after {
                content: "";
                position: absolute;
                right: 0;
                width: 0px !important;
                height: 0px !important;
                background-color: white !important;
                border-radius: 0px !important;
              }
            }
          }
        }

        [role="title"],
        [role="admin-title"],
        [role="archived"] {
          display: flex;
          align-items: center;
          padding-left: 37px !important;
          width: 260px !important;
          height: 60px;
          margin-bottom: 0px;
          margin-top: 0px;
        }

        [role="title"] {
          border-bottom: 1px solid var(--light-blue-grey) !important;

          &.ant-menu-item-selected svg path {
            fill: var(--violet);
          }

          &.ant-menu-item-selected {
            &.projectMainItemNotSelected {
              color: var(--new-grey);

              svg path {
                fill: var(--new-grey);
              }

              &:after {
                content: "";
                position: absolute;
                right: 0;
                width: 0px !important;
                height: 0px !important;
                background-color: white !important;
                border-radius: 0px !important;
              }
            }
          }

        }

        [role="admin-title"] {
          &.ant-menu-item-selected {
            color: var(--blue-main);
          }

          &.ant-menu-item-selected svg path {
            fill: var(--blue-main);
          }
        }

        [role="menuitem"] {
          border: none;
        }

        .ant-menu-item-selected .anticon svg {
          visibility: visible;
        }
      }

      li[role="menuitem"]::before {
        content: "";
        padding-right: 6rem;
      }
    }

    ul {
      display: inline-block;

      li {
        color: var(--new-grey);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .icon {
    width: 48px;
    height: 48px;
  }

  .iconSpace {
    margin-right: 13px;
    margin-left: 0 !important;
  }

  .arrow {
    position: absolute;
    right: 17px;
    transition: all 0.3s ease !important;
    transform: rotate(90deg);
  }

  .arrowDown {
    transform: rotate(0deg);
  }

  .arrowRight {
    transform: rotate(90deg);
  }
}

.generalContainer,
.contractContainer {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
}

.contractContainerItem {
  direction: ltr;
  width: fit-content;
}

.archivedContainer {
  overflow: auto;
}

.inputProject {
  border: none;
  margin-left: 15px !important;

  &:hover,
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.adminItem {
  color: var(--new-grey) !important;

  svg path {
    fill: var(--new-grey) !important;
  }

  &:after {
    display: none !important;
  }
}
