.wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 550px 1fr;

    > div {
        grid-column: 2;
    }

    :global {
        .ant-tabs {
            grid-column: 2;

            .ant-tabs-tab {
                button {
                    position: relative;
                    right: -8px;
                    margin-left: 18px;
                    display: none;
                    vertical-align: center;
                    width: 24px;
                    height: auto;
                    background-color: transparent;
                    border-color: transparent;

                    .anticon {
                        margin: 0;
                        font-size: 15px;
                    }
                }

                &.ant-tabs-tab-active {
                    button {
                        display: inline-block;
                    }
                }
            }

            .ant-spin-nested-loading,
            .ant-spin-container {
                position: static;
            }

            .ant-spin-blur {
                opacity: 1;
            }

            .ant-spin-nested-loading > div > .ant-spin,
            .ant-spin-container::after {
                top: -10px;
            }

            .ant-form-item-explain,
            .ant-form-item-extra {
                background-color: var(--common-bg);
            }
        }

        .ant-upload.ant-upload-drag {
            background-color: transparent;
            border: 1px dashed #9492CA;
            border-radius: 10px;

            .ant-upload-drag-icon {
                margin-bottom: 12px;

                svg path {
                    color: var(--blue-main);
                }
            }

            &:not(.ant-upload-disabled):hover {
                border-color: #D4D3EF;
            }

            p.ant-upload-text {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #060357;
            }

            p.ant-upload-hint {
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #9F9EA9;
            }
        }

        .ant-upload-list {
            .ant-upload-list-item-info {
                .ant-upload-list-item-name {
                    color: var(--blue-main);

                    &:hover {
                        color: var(--blue-hover);
                    }
                }

                &:hover {
                    background-color: transparent;
                }
            }

            .anticon-spin {
                display: none;
            }

            .ant-upload-list-item-progress {
                padding-left: 0;
            }

            .ant-upload-list-item-card-actions-btn {
                opacity: 1;
                background-color: transparent;
            }
        }

        .smooth-dnd-draggable-wrapper {
            form.ant-form {
                margin-bottom: 12px;

                input {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.form {
    grid-column: 2;

    :global {
        .ant-form-item {
            .ant-form-item-label {
                text-align: left;

                > label {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--grey);

                    &.ant-form-item-required {
                        &:before {
                            content: none;
                        }
                    }
                }
            }

            .ant-input,
            .ant-input-number,
            .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                width: 100%;
                height: var(--input-height);
                align-items: center;
                background: var(--white);
                border: 1px solid var(--light-blue);
                border-radius: var(--input-radius);
                font-size: 16px;
                font-weight: 400;
                color: var(--blue-headline);
            }

            .ant-select-selection-placeholder,
            .ant-input:placeholder-shown,
            .ant-input-number-input:placeholder-shown {
                font-size: 16px;
                font-weight: 400;
                color: var(--grey);
                opacity: 1;
            }

            textarea.ant-input {
                height: auto;
            }

            .ant-input-number-input {
                height: 38px;
            }
        }
    }
}

.itemForm {
    position: relative;

    :global {
        .ant-form-item.ant-row {
            margin-right: 0px;

            &:first-of-type {
                margin-right: 14px;
            }

            > .ant-form-item-label {
                display: none;
            }
        }

        .ant-btn {
            height: 40px;

            &.ant-btn-text {
                font-weight: normal;
                font-size: 15px;
                line-height: 18px;
                color: var(--dark-grey);

                &[type='submit'] {
                    color: var(--blue-main);
                }
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:hover {
                color: var(--blue-hover);
            }
        }
    }
}

.itemTabForm {
    @extend .itemForm;
    z-index: 99;
    position: absolute;
    top: -10px;
    left: -2px;
}

.item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 4px 6px;
    margin-bottom: 8px;
    font-size: 12px;
    color: var(--dark-grey);
    border-radius: 3px;
    background: rgba(160, 135, 241, 0.2);
    border: 1px solid #602BF5;
    cursor: move;
}

.itemNotEditable {
    border-color: var(--grey);
    background-color: var(--common-contract-bg);
}

.dropPreview {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
}
