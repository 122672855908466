@import "../../styled/variables";
@import "../../styled/breakpoints";

.wrapper {
  background-color: var(--bg-mainContainer);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto !important;
  position: relative;

  .content {
    overflow-x: auto;
  }

  .layout {
    margin-top: var(--header-height);

    @include breakpoint(md) {
      margin-top: 0 !important;
    }

    @include breakpoint(sm) {
      margin-top: 0;
    }
  }

  .background {
    background-color: var(--common-bg);
  }

  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }

  @include breakpoint(sm) {
    flex-wrap: unset !important;
  }
}

.helpContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  background: var(--bg-mainContainer);
  padding: 0 55px;
  justify-content: end;
  align-items: end;
  margin-top: 90px;
  padding-right: 6%;
  @include breakpoint(sm) {
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding: unset !important;
    position: unset !important;
    bottom: unset !important;
  }

  .helpButton {
    width: 137px;
    height: 46px;
    font-size: 18px;
    padding: 10px 5px;
    background-color: var(--blue-main);
    color: var(--white);
    text-align: center;
    border-radius: 4px;
    margin-bottom: -4px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(sm) {
      margin-top: 46px;
      width: 100px;
      height: 39px;
      font-size: 14px;
    }
  }
}

:global {
  .ant-layout-sider.sider {
    background-color: var(--white);
    box-shadow: 6px 8.5rem 4px 1px #0000000d;
    padding: 0;
    z-index: 1;
  }

  .ant-layout-content.main {
    background-color: var(--bg-mainContainer);
    margin-top: 0px !important;
  }
}
