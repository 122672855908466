@import '../../styled/variables';
@import '../../styled/breakpoints';

.wrapper {
    position: relative;

    :global {
        .react-pdf__Document {
            max-width: 100%;
            min-height: 869px;
        }

        .react-pdf__Page canvas {
            max-width: 100%;
            margin: 0 auto;
            height: auto;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        }

        .react-pdf__Page__annotations {
            display: none;
        }

        .react-pdf__message {
            font-size: 13px;
            line-height: 185%;
            text-align: center;
            color: var(--dark-grey);
        }

        @media screen and (max-width: $tablet) {
            .react-pdf__Document {
                min-height: auto;
            }

            .react-pdf__Page canvas {
                height: auto !important;
            }
        }
    }
}

.wrapperFull {
    composes: wrapper;
    .navigation {
        > button.ant-btn {
            position: absolute;
            top: calc(50% - 50px);
            left: -70px;
            width: 50px;
            height: 50px;
            transform: translateY(-50%);

            &:last-child {
                left: auto;
                right: -70px;
            }
        }
    }
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 49px auto 41px auto;

    .text {
        margin: 0 12px;
        left: 0;
        transform: translateX(0);
        width: auto;
        font-size: 13px;
        line-height: 185%;
        text-align: center;
        color: var(--dark-grey);
        @include breakpoint(sm) {
            line-height: 1.2;
        }
    }

    .button {
        border-radius: 100% !important;
        border-color: var(--light-blue-grey) !important;
        background-color: var(--light-blue-grey) !important;

        > span {
            font-size: 14px;
        }
    }

    @media screen and (max-width: $tablet) {
        margin: 20px auto;
    }
}
