@import "../../../styled/variables";
@import "../../../styled/breakpoints";

.content {
  .title {
    margin-bottom: 23px !important;
    font-weight: 700 !important;
    font-size: 3.5rem !important;
    line-height: 0.8 !important;
    color: #3331b1 !important;

    @include breakpoint(sm) {
      font-size: 3.3rem !important;
      margin-bottom: 17px !important;
    }
  }

  .description {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
    margin: 0 auto 33px auto;
    max-width: 956px;

    @include breakpoint(sm) {
      font-size: 11px;
      line-height: 17px;
      margin: 0 auto 22px auto;
    }
  }

  .nav {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .mobileBtn {
      display: none;
    }

    >div:first-of-type {
      text-align: left;
    }

    >div:last-child {
      grid-column: 3;
      text-align: right;

      :global {
        .ant-btn {
          width: 200px;
        }

        a.ant-btn {
          padding-top: 0.01px;
        }

        .ant-btn:not(.ant-btn-primary) {
          padding: 4px 16px !important;
          background-color: transparent;
          border: 2px solid var(--light-blue-grey);
          font-weight: 600;
          font-size: 16px;
          color: var(--dark-grey);

          &:hover,
          &:focus {
            color: var(--blue-hover);
            background-color: transparent;
            border-color: var(--light-border-blue);
          }
        }

        .anticon {
          color: var(--grey);
        }
      }
    }

    :global {
      .ant-btn {
        margin-bottom: 10px;
        height: 46px;
        font-weight: 600;
        font-size: 18px;
        line-height: 2;
        border-radius: 4px;

        &.ant-btn-primary {
          margin-bottom: 12px;

          .ant-btn-loading-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto;
          }
        }

        &.ant-btn-sm {
          padding: 4px 24px;
        }
      }
    }

    @media screen and (max-width: $desktop) {
      width: 100%;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      max-width: 580px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: $mobile_full) {
      grid-template-columns: 1fr;

      .mobileBtn {
        display: block;
        border: 2px solid var(--blue-main) !important;
        color: var(--blue-main) !important;
        font-size: 18px !important;
        font-weight: 600 !important;
      }

      >div:first-of-type {
        display: none;
      }

      >div:last-child {
        grid-column: 1;
        text-align: left;
      }

      :global {
        .ant-btn {
          width: 100% !important;
          margin-bottom: 15px;

          &.ant-btn-primary {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .configContainer {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .tabs {
    margin-top: 15px;
    margin-right: 7px;
    margin-bottom: 15px;
  }

  .stikyContainer {
    position: sticky;
    top: 125px;
    padding-bottom: 40px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .documentContainer {

    @include breakpoint(sm) {
      margin-top: 10px;
    }

    .stikyContainer {
      position: sticky;
      top: 125px;
      padding-bottom: 40px;

      @include breakpoint(sm) {
        padding-bottom: 0;
      }
    }

    .documentContainer {
      @include breakpoint(sm) {
        margin-top: 10px;
      }
    }

    .goToFullEditor {
      align-items: center !important;
      background-color: var(--orange) !important;
      border: 2px solid var(--orange) !important;
      border-radius: 4px !important;
      color: var(--white) !important;
      display: inline-flex !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      justify-content: center !important;
      line-height: 22px !important;
      margin-bottom: 12px !important;
      min-height: 46px !important;
      min-width: 200px !important;
    }
  }
}
