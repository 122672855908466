.headerContainer {
  display: flex !important;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  margin-top: 70px;
  margin-bottom: -72px;

  .headerTitle {
    display: flex;
    align-items: center;
    gap: 9px;
    width: 100%;

    .icon {
      circle {
        fill: var(--violet);
      }
    }

    .title {
      color: var(--violet);
      font-size: 13px;
      font-weight: 700;
    }
  }

  .actionIcons {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .addIcon {
    transform: translateY(2px);
    cursor: pointer;
    margin-left: -2px;
  }

  .headerActions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 9px;
    width: 100%;
  }

  .headerSearch {

    .inputSearch {
      margin-left: -10px;
    }

    .addIcon {
      margin-left: 2px;
    }
  }
}

:global {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none !important;
    }
  }

  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 0 !important;
      border-radius: 6px 6px 0 0 !important;
      margin-top: -15px !important;
    }

  }
}

.contentContainer {
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
    background: none;
    border: none !important;
    color: var(--white) !important;
    font-size: 15px;
    font-weight: 700;
    height: 50px;
    display: flex;
    align-items: center;

    &:focus {
      color: var(--white) !important;
    }
  }

  .iconButton {
    width: 25px !important;
    height: 25px !important;
    margin-right: 4px;
  }

  .iconButtonContract {
    margin-right: 4px;
  }

  .newProjectButton {
    border-radius: 6px 6px 0 0 !important;
    background-color: var(--violet-light) !important;
  }

  .newContractButton {
    border-radius: 0 0 6px 6px !important;
    background-color: var(--contract-button) !important;
  }

  .projectHidden {
    border-radius: 6px !important;
  }
}
