.wrapper {
    position: relative;
    //height: 500px;
    //overflow-y: scroll;

    :global {
        .smooth-dnd-container {
            counter-reset: posCounter;

            .smooth-dnd-ghost {
                h3:before {
                    content: '-';
                }
            }
        }

        .smooth-dnd-draggable-wrapper {
            overflow: visible !important;
        }
    }
}

.container {
    position: relative;
    padding: 7px 11px 13px;
    margin: 5px 0;
    background: var(--white);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

    > h3 {
        padding: 0 50px 0 0;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--dark-grey);
        cursor: grab;

        &:before {
            counter-increment: posCounter;
            content: counter(posCounter);
            display: inline-block;
            width: 20px;
            height: 20px;
            padding: 3px;
            margin: 0 7px 0 0;
            text-align: center;
            vertical-align: middle;
            border: 1px solid var(--light-blue-grey);
            border-radius: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 1;
            color: var(--light-blue-grey);
        }
    }

    button.button {
        position: absolute;
        top: 3px;
        right: 7px;
        width: 20px;
        min-width: auto;
        height: 20px;
        background-color: transparent !important;
        border-color: transparent !important;

        &:not(:last-of-type) {
            margin-right: 28px;
        }
    }

    ul {
        list-style: disc;
        padding-left: 15px;

        > li {
            padding: 4px 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            color: var(--grey);
        }
    }

    :global {
        .smooth-container-no-drop {
            > div > div {
                cursor: default;
            }
        }
    }
}

.item {
    display: inline-block;
    padding: 4px 6px;
    margin-bottom: 8px;
    vertical-align: middle;
    font-size: 12px;
    color: var(--dark-grey);
    border-radius: 3px;
    cursor: pointer;

    background: rgba(160, 135, 241, 0.2);
    border: 1px solid #602BF5;

    > span {
        display: block;
    }

    :global {
        .drag-handler {
            display: inline-block;
            vertical-align: baseline;
            margin-right: 8px;
            padding: 1px;
            cursor: grab;
        }
    }
}

.message {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey);
}

.dropPreview {
    display: inline-block;
    vertical-align: middle;
    margin: 10px 0;
    padding: 14px 6px;
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
}

.fieldDropPreview {
    display: inline-block;
    margin: 5px 0;
    background-color: transparent;
    border: 1px dashed #abc;
}
