.itemGeneralSelected,
.itemArchivedSelected,
.itemSelected {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;

  .swiper {
    background: var(--white);
    width: 70px;
    height: 4px;
    transform: translateY(10px);
  }

}

.itemGeneralSelected {
  background: var(--violet-pale) !important;
  color: var(--white);
}

.itemArchivedSelected {
  background: var(--selector-item) !important;
  color: var(--selector-text);
}
