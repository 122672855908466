:root {
  // colors
  --white: #ffffff;
  --common-bg: #f8fbff;
  --common-intro-bg: #f8f8ff;
  --common-intro-border: #d4d3ef;
  --common-contract-bg: #f3f3f3;
  --base-black: #050a1a;
  --black-secondary: #000000;
  --draft: #979797;
  --sent: #FBB500;
  --viewed: #FF9900;
  --signed: #308FFF;
  --executed: #23AD41;
  --disabled-button: #D8DEE8;


  --danger: #ff4d4f;
  --orange: #fe6646;
  --green: #a4d69c;

  --grey: #9f9ea9;
  --dark-grey: #4f4f4f;
  --light-grey: #d9e1e7;
  --blue-main: #3331b1;
  --blue-main-20: rgba(51, 49, 177, 0.2);
  --blue-headline: #060357;
  --blue-hover: #19177d;
  --blue-arrow: #8ab0cc;

  --light-blue-grey: #e5e9f4;
  --light-blue-l: #ebeff7;
  --light-blue: #dfe8f3;
  --lightest-blue: #d3e0f0;
  --lightest-blue-l: #e6f7ff;
  --light-text-blue: #b1c7e2;
  --text-blue: #899ac4;
  --light-border-blue: #bbbbe1;
  --light-back: #f1f0ff;
  --light-added: #e9e9f2;
  --light-border: #eef3fa;

  --easy: #a087f1;
  --notification-color: #602bf5;

  --gray-tr: #0e0d28;
  --other-arg-back: #fcfcff;
  --light-border-op: rgba(51, 49, 177, 0.05);
  --shadeow-box-agr: rgba(0, 0, 0, 0.1);
  --super-light-border: #ededed;
  --back-for-icon: #e6e6ff;
  --tab-line: #d3d3d3;
  --pack-icon: #bdbdbd;
  --head-c: #f2f2fb;
  --head-bg: linear-gradient(275.24deg,
      #f7eda5 3.7%,
      rgba(247, 237, 165, 0) 51.29%),
    linear-gradient(149.41deg, #d1e7ff 16.74%, rgba(209, 231, 255, 0) 87.62%),
    #c284f0;

  // Fonts
  --font_default: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --font_inter: Inter, Arial, sans-serif;
  --font_verdana: Verdana, Arial, sans-serif;

  // Sizes
  --input-height: 40px;
  --input-height-xl: 48px;
  --input-radius: 8px;
  --header-height: 105px;
  --header-mobile-height: 72px;

  //Redesign
  --main-background: #e5e5e5;
  --violet: #6418c3;
  --violet-light: #6d37f9;
  --violet-pale: #9f56fa;
  --purple: #a087f1;
  --bg-mainContainer: #f9f9f9;
  --header-icons: #a5a5a5;
  --contract-button: #fe5d4b;
  --shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  --input-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  --new-grey: #a5a5a5;
  --new-dark-grey: #7a7a7a;
  --new-line-gray: #c2c2c2;
  --new-text-gray: #aba7a7;
  --new-black: #202020;
  --close-icon: #b1b1b1;
  --category-table: #c8c3ff;
  --category-switch: #8de238;
  --category-switch-off: #9b9b9b;
  --input-selector: #167ada;
  --selector-text: #828282;
  --selector-item: #efefef;
  --mobile-header: #fcfcfe;
  --delete-data: #eb5757;
  --scroll-bar: #7a7a7d;
  --header-table: #e5e4ff;
  --status-view: #E8EEF2;
  --cancel-button: #D6D6EF;
  --archived-status: #CCC4C3;
}

// Media Queries
$mobile: 320px;
$mobile_full: 480px;
$tablet: 768px;
$tablet_full: 992px;
$desktop: 1200px;
