@import  '../../../styled/breakpoints';


.mainContainer {
  background-color: var(--common-intro-bg);
  border-radius: 8px;
  border: 1px solid var(--common-intro-border);
  min-height: 184px;
  padding: 28px 30px 19px 26px;
  text-align: left;
  @include breakpoint (sm){
    text-align: center;
    padding: 21px 44px;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    color: var(--blue-main);
    line-height: 18px;
    @include breakpoint (sm){
      font-size: 14px;
    }
  }
}