:global {
  .PartiesSection- {
    &column {
      padding-right: 10px;
      margin-bottom: 24px;
      p {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        font-family: var(--font_verdana);
        margin-bottom: 8px;
        word-break: break-all;
      }
      &-by {
        margin-top: 24px;
        margin-bottom: 24px;
      }
      &-title {
        min-height: 24px;
      }
      img {
        display: none;
      }
    }
  }
}