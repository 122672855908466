.briefcaseModal {
  :global {
    .ant-modal-header {
      background-color: var(--purple);
    }

    .ant-modal-content .ant-modal-title {
      color: var(--white);
    }

    .ant-modal-content {
      border: none;
    }

    .ant-modal-content .ant-modal-close {
      display: flex;
      color: var(--white);
    }

    .ant-modal-body p {
      font-family: Inter;
      font-size: 21px;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: center;
      padding-bottom: 2rem;
    }

    .ant-modal-content .ant-modal-body {
      padding: 2rem 8.5rem;
    }

    .ant-modal-footer {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
    }

    .ant-btn {
      background-color: var(--orange);
      color: var(--white);
      border: none;
      justify-content: center;
      width: 158px;
      height: 54px;
    }

    .ant-btn:not([disabled]):hover {
      background-color: var(--white);
      color: var(--orange);
      border: 1px solid var(--orange);
    }

    .ant-btn>span {
      width: 158px;
      text-align: center;
    }
  }

  p:first-child {
    font-weight: 700;
  }

  p:nth-child(3) {
    padding-bottom: 1rem;
  }

  p:nth-child(4) {
    padding-bottom: 0rem;
  }

  .textModal {}

  .showButton {
    color: rgba(0, 0, 0, 0.4);
    text-decoration-line: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.mobileContainer {
  min-width: 40rem;
}
