.revert {
  flex-direction: column-reverse !important;
}

.revert,
.container {
  display: flex;
  flex-direction: column;
  touch-action: none;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    svg {
      transform: translateY(-2px);
    }
  }

  .selector {
    position: relative;
    display: flex;
    flex-direction: column;

    .iconContainer {
      z-index: 99;
      padding: 20px 10px;
      position: absolute;
      top: 10px;
      right: 16px;
    }

  }

  .generalItemSelected,
  .generalContainer {
    ul {
      background: var(--violet-pale);
      li {
        color: var(--white);
        font-size: 13px;
        transition: all .2s;
        border: 1px solid var(--violet-pale);

        &:hover {
          border-top: 1px solid var(--common-intro-bg);
          border-bottom: 1px solid var(--common-intro-bg);
        }
      }
    }
  }

  .generalItemSelected {
    button {
      background: var(--violet-pale) !important;
    }
  }

  .archivedItemSelected {
    button {
      background: var(--selector-item) !important;
      color: var(--selector-text) !important;

      span:nth-child(2) {
        background: var(--selector-text) !important;
      }
    }
  }

  .archiveContainer {
    ul {
      li {
        color: var(--selector-text);
        font-size: 14px;
        background: var(--selector-item);
        border: 2px solid transparent;
        cursor: pointer;
        transition: all .2s;

        &:hover {
          margin-bottom: 3px !important;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
          border: 2px solid var(--common-intro-bg);
        }
      }
    }
  }

  .inputContainer {
    background: var(--violet-pale);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
      border-left: 2px solid var(--light-blue);
      border-right: 2px solid var(--light-blue);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        background: var(--light-blue);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -5px;
        right: -5px;
        background: var(--light-blue);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      .input {
        background: rgba(255, 255, 255, 0.3);
        text-align: center;
        color: var(--white);
        font-size: 15px;
        font-weight: 700;
        text-transform: capitalize;

        &:focus,
        &:hover {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }

  }
}

.arrowIcon {
  transform: translate(3px, -2px) !important;
}
