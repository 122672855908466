
:global {
    .thank-you-modal {
        .button {
            color: var(--blue-main) !important;
            background-color: var(--blue-main-20) !important;
            border: none !important;
        }

        .ant-modal-header {
            padding-top: 46px !important;
            padding-bottom: 0 !important;
        }

        .ant-modal-body {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            font-size: 17px;
            text-align: center;
        }

        .ant-modal-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 0 !important;
            padding-bottom: 36px !important;
        }
    }
}

